import axiosInstance from "../axios/axios"

export const fetchProducts = async (category: any) => {
    if (!category) {
        return axiosInstance.post(`/product/fetch-products`)
    } else {
        return axiosInstance.post(`/product/fetch-products?category=${category}`)
    }
}

export const getTopFourProds = async () => axiosInstance.post('/product/top-4-products');

export const fetchFreeProducts = async () => axiosInstance.post('/free-product/fetch-free-products');

export const fetchCategories = async () => axiosInstance.post('/category/fetch-category');

export const getHomePageBanner = async (value: any) => axiosInstance.post(`/hotel/get-home-banner`, value);

export const createUser = async (value: any) => axiosInstance.post(`/hotel/create-hotel`, value);

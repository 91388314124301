import { Box, Button, Checkbox, Container, FormControlLabel, Grid, Modal, Slide } from '@mui/material';
import SuperAdminNavbar from './SuperAdminNavbar';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { fetchHotelsList, getCategoriesInOrder, getProdsInOrder, updateAssignedCategories, updateAssignedProducts } from '../apis/SuperAdminApi';
import { useSelector } from 'react-redux';
import Forms from '../forms/Forms';
import { primaryColor } from '../components/Styles/styles';
import CloseIcon from '@mui/icons-material/Close';
import { AxiosResponse } from 'axios';
import { defaultHotelColumn } from '../utils/utils';

const AdminDashboard = () => {

  const apiRef = useGridApiRef();
  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [deleteIcon, setDeleteIcon] = useState(false)
  const [selectedRow, setSelectedRow] = useState({ type: "hotel", id: [] })
  const [editMode, setEditMode] = useState({ state: false, dataType: '' })
  const modal = useSelector ((store: any) => store.openModal)
  const [fetchProducts, setfetchProducts] = useState([])
  const [checkedState, setCheckedState] = useState<any>([]);
  const [buttondisabled, setButtondisabled] = useState(true)
  const [initialCheckedState, setInitialCheckedState] = useState([]);
  const [loading, setLoading] = useState(false)

  const handleCheckboxChange = (productId: any, index: number) => {
    const updatedCheckedState = checkedState.map((item: any, pos: number) =>
      pos === index ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const getCheckedProductIds = () => {
    return fetchProducts
      .filter((_, index) => checkedState[index])
      .map((product: any) => product._id);
  };

  const handleChange = (selectionModel: any) =>{
    if (selectionModel.length > 0) setDeleteIcon(true)
    else setDeleteIcon(false)
    let objectsTotDelete: any = { type: "hotel", id: [] }
    selectionModel.forEach((record: any) => {
      objectsTotDelete.id.push(rows[record - 1]);
    });
    setSelectedRow(objectsTotDelete)
  }

  const fetchHotelAccounts = async() => {
    try {
        setLoading(true)
        const response = await fetchHotelsList()
        if(response.data.length === 0){
          setColumns(defaultHotelColumn)
          setRows([])
          setSelectedRow({ type: "hotel", id: [] })
          setLoading(false)
          return
        }

        const filteredKeys = Object.keys(response.data[0]);

        // const filteredKeys = keys.filter(key => key !== "_id");

        const generatedColumns: any = [
          {
            field: 'id',
            headerName: 'ID',
            width: 50
          },
          ...filteredKeys.map(key => ({
            field: key,
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            width: key === 'order' ? 100 : 150
          }))
        ];
        setColumns(generatedColumns);
        const generatedRows = response.data.map((row: {
        }, index: any) => ({
          id: index + 1,
          ...row,
        }))
        setRows(generatedRows);
    } catch (error) {
      console.log("🚀 ~ fetchHotelAccounts ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const handleUnselectAll = () => {
    apiRef.current.setRowSelectionModel([]);
    setDeleteIcon(false);  // Optionally reset the delete icon state
    setSelectedRow({ type: "hotel", id: [] });  // Reset selected rows
  };

  useEffect(()=>{
    if(columns.length === 0){
      fetchHotelAccounts()
    }
  },[columns])
  
  const hasChanges = () => {return false}

  const fetchAssignedProds = async () => {
    try {
      let response: AxiosResponse<any, any>
      if(editMode.dataType === "products"){
        response = await getProdsInOrder(selectedRow.id[0]['_id'])
      }else{
        response = await getCategoriesInOrder(selectedRow.id[0]['_id'])
      }
      setfetchProducts(response.data.newArray)
      const initialChecked = response.data.newArray.map((_: any, index: number) => index < response.data.assignedProductsLength);
      setCheckedState(initialChecked);
      setInitialCheckedState(initialChecked);

    } catch (error) {
      console.log("🚀 ~ fetchAssignedProds ~ error:", error)
    }
  }

  useEffect(()=>{
    if(editMode.state && fetchProducts.length ===0 ){
      fetchAssignedProds()
    }
    if(!editMode.state){
      setfetchProducts([])
    }
  },[editMode.state])

  const handleSubmit =async () => {
    try {
      const checkedProductIds = getCheckedProductIds();

      const data = {checkedProductIds,hotel_id: selectedRow.id[0]['_id']}
      if(editMode.dataType === "products"){
        await updateAssignedProducts(data)
      }else{
        await updateAssignedCategories(data)
      }
      setfetchProducts([])
      setEditMode({ state: false, dataType: '' })
      setButtondisabled(true)
      handleUnselectAll()
      setTimeout(()=>{
        alert('Data updated successfully')
      },500)
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)
    }

  }

  useEffect(() => {

    let hasChanges = false;

    // Compare all checkboxes
    for (let index = 0; index < fetchProducts.length; index++) {
      const data: any = fetchProducts[index];
      if (checkedState[index] !== initialCheckedState[index]) {
        console.log('Change detected:', index, ' ', data._id);
        hasChanges = true;
        break;
      }
    }
    setButtondisabled(!hasChanges);

  }, [checkedState])

  return (
    <>
      <SuperAdminNavbar setEditMode={setEditMode} hasChanges={hasChanges} handleUnselectAll={handleUnselectAll} selectedRow={selectedRow} deleteIcon={deleteIcon} fetchHotelAccounts={fetchHotelAccounts}/>
      { modal && <Forms setColumns={setColumns} columns={columns} formName={"hotel"}/>}

      <Box sx={{ width: '100vw', height: '82vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '1%' }}>
        <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column', marginTop: '1%' }}>
          <DataGrid
            loading={loading}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            sx={{
              flexGrow: 1, // Make the DataGrid grow to fill the Container
              width: '100%',
              border: 'none'
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={handleChange}
          />
          <Modal
            open={editMode.state}
            onClose={() => setEditMode({ state: false, dataType: '' })}
            sx={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Slide direction="up" in={editMode.state} mountOnEnter unmountOnExit>
              
              <Grid container sx={{ width: '942px', maxHeight: '70%',minHeight: '50%', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'scroll',borderRadius: '8px' }}>
                <div style={{ width: '100%', marginTop: '2%', display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }}>
                  <div className='form-footer-header' style={{ marginLeft: '2%' }}>
                    Assigned/UnAssigned Products
                    <CloseIcon onClick={() => setEditMode({ state: false, dataType: '' })} sx={{ cursor: 'pointer' }} />
                  </div>
                </div>
                {fetchProducts.map((data: any, index) => (
                  <Grid item xs={6} key={index}>
                    <FormControlLabel
                      sx={{ marginLeft: '10%' }}
                      control={
                        <Checkbox
                          checked={checkedState[index]}
                          onChange={() => handleCheckboxChange(data._id, index)}
                        />
                      }
                      label={data.product_name || data.name}
                    />
                  </Grid>
                ))}
                <Grid item xs={6} sx={{ visibility: 'hidden' }} />
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                  <Button
                    id='button'
                    disabled={buttondisabled}
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{ height: '45px', width: '160px', fontSize: '16px', backgroundColor: primaryColor, ":hover": { backgroundColor: primaryColor } }}
                  >
                    Save changes
                  </Button>
                </Grid>
              </Grid>

            </Slide>
          </Modal>
        </Container>
      </Box>
    </>
  )
}

export default AdminDashboard
import axiosInstance from "../axios/axios"

export const SuperAdminLoginApi = (values: any) => axiosInstance.post('/super-admin/login', values );

export const fetchHotelsList = () => axiosInstance.post('/super-admin/fetch-hotels-list' );

export const fetchProductsForSuperAdmin = () => axiosInstance.post('/super-admin/fetch-products-list' );

export const deleteHotels = (values: any) => axiosInstance.post('/hotel/delete-hotel', {values} );

export const getProdsInOrder = (value: any) => axiosInstance.get(`/super-admin/fetch-products-in-order?hotel_id=${value}` );

export const getCategoriesInOrder = (value: any) => axiosInstance.get(`/super-admin/fetch-category-in-order?hotel_id=${value}` );

export const updateAssignedProducts = (value: any) => axiosInstance.post('/super-admin/update-assigned-products',value)

export const updateAssignedCategories = (value: any) => axiosInstance.post('/super-admin/update-assigned-categories',value)

export const fetchCategoryForSuperAdmin = () => axiosInstance.get(`/super-admin/fetch-super-admin-category`)

export const fetchTripXOXOcategories = () => axiosInstance.get(`/super-admin/fetch-tripoxoxo-category`)

export const deleteTpXOXOproducts = (values: any) => axiosInstance.post('/super-admin/delete-tp-product', {values} );

export const deleteCategory = (values: any) => axiosInstance.post('/super-admin/delete-category', {values}) 

export const uploadToS3 = (value: any, formName: any) => axiosInstance.post(`/super-admin/upload-product-to-s3?formName=${formName}`, value, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

export const testApi = (value: any, formName: any) => axiosInstance.post(`/super-admin/testApi?formName=${formName}`, value, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

export const deleteImageFromS3 = (values: any) => axiosInstance.post('/super-admin/delete-image-from-s3',{ imageUrl: values }) 
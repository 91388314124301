export const defaultColumns = [
    {
        "field": "id",
        "headerName": "ID",
        "width": 80
    },
    {
        "field": "product_name",
        "headerName": "Product_name",
        "width": 150
    },
    {
        "field": "image",
        "headerName": "Image",
        "width": 150
    },
    {
        "field": "price",
        "headerName": "Price",
        "width": 100
    },
    {
        "field": "order",
        "headerName": "Order",
        "width": 100
    },
    {
        "field": "offer",
        "headerName": "Offer",
        "width": 90
    },
    {
        "field": "link",
        "headerName": "Link",
        "width": 150
    },
    {
        "field": "category",
        "headerName": "Category",
        "width": 150
    },
    {
        "field": "attribute",
        "headerName": "Attribute",
        "width": 150
    },
    {
        "field": "createdAt",
        "headerName": "CreatedAt",
        "width": 150
    },
    {
        "field": "updatedAt",
        "headerName": "UpdatedAt",
        "width": 150
    },
    {
        "field": "latitude",
        "headerName": "Latitude",
        "width": 120
    },
    {
        "field": "longitude",
        "headerName": "Longitude",
        "width": 120
    }
]

export const defaultFreeProdsColumns = [
    {
        "field": "id",
        "headerName": "ID",
        "width": 50
    },
    {
        "field": "_id",
        "headerName": "Product_id",
        "width": 150
    },
    {
        "field": "free_product_name",
        "headerName": "Free_product_name",
        "width": 150
    },
    {
        "field": "image",
        "headerName": "Image",
        "width": 150
    },
    {
        "field": "description",
        "headerName": "Description",
        "width": 150
    },
    {
        "field": "link",
        "headerName": "Link",
        "width": 150
    },
    {
        "field": "eminity",
        "headerName": "Eminity",
        "width": 150
    },
    {
        "field": "hotel_id",
        "headerName": "Hotel_id",
        "width": 150
    },
    {
        "field": "section_id",
        "headerName": "Section_id",
        "width": 150
    },
    {
        "field": "createdAt",
        "headerName": "CreatedAt",
        "width": 150
    },
    {
        "field": "updatedAt",
        "headerName": "UpdatedAt",
        "width": 150
    },
    {
        "field": "latitude",
        "headerName": "Latitude",
        "width": 120
    },
    {
        "field": "longitude",
        "headerName": "Longitude",
        "width": 120
    }
]

export const defaultCategoriesColumns = [
    {
        "field": "id",
        "headerName": "ID",
        "width": 50
    },
    {
        "field": "_id",
        "headerName": "Category_id",
        "width": 150
    },
    {
        "field": "name",
        "headerName": "Name",
        "width": 150
    },
    {
        "field": "image",
        "headerName": "Image",
        "width": 150
    },
    {
        "field": "order",
        "headerName": "Order",
        "width": 100
    },
    {
        "field": "createdAt",
        "headerName": "CreatedAt",
        "width": 150
    },
    {
        "field": "updatedAt",
        "headerName": "UpdatedAt",
        "width": 150
    }
]

export const superAdminProductsDefaultColumn = [
    {
        "field": "id",
        "headerName": "ID",
        "width": 80
    },
    {
        "field": "_id",
        "headerName": "Product_id",
        "width": 150
    },
    {
        "field": "product_name",
        "headerName": "Product_name",
        "width": 150
    },
    {
        "field": "image",
        "headerName": "Image",
        "width": 150
    },
    {
        "field": "price",
        "headerName": "Price",
        "width": 100
    },
    {
        "field": "order",
        "headerName": "Order",
        "width": 90
    },
    {
        "field": "offer",
        "headerName": "Offer",
        "width": 90
    },
    {
        "field": "link",
        "headerName": "Link",
        "width": 150
    },
    {
        "field": "category",
        "headerName": "Category",
        "width": 150
    },
    {
        "field": "attribute",
        "headerName": "Attribute",
        "width": 150
    },
    {
        "field": "hotel_id",
        "headerName": "Hotel_id",
        "width": 150
    },
    {
        "field": "latitude",
        "headerName": "Latitude",
        "width": 120
    },
    {
        "field": "longitude",
        "headerName": "Longitude",
        "width": 120
    }
]

export const defaultHotelColumn = [
    {
        "field": "id",
        "headerName": "ID",
        "width": 50
    },
    {
        "field": "hotel_name",
        "headerName": "Hotel_name",
        "width": 150,
    },
    {
        "field": "address",
        "headerName": "Address",
        "width": 150,
    },
    {
        "field": 'username', 
        "headerName": 'Username',
        "width": 150
    },
    {
        "field": 'password',
        "headerName": 'Password',
        "width": 150
    }
    // {
    //     "field": "role",
    //     "headerName": "Role",
    //     "width": 150,
    // },
]

export const Slide = {
    pageVariants: {
        initial: {
            opacity: 0,
            x: "-100vw",
        },
        in: {
            opacity: 1,
            x: 0,
        },
        out: {
            opacity: 0,
            x: "100vw",
        },
    },
    pageTransition: {
        type: "tween",
        ease: "anticipate",
        duration: 0.5,
    }
}

export const Fade = {
    pageVariants: {
        initial: {
          opacity: 0,
        },
        in: {
          opacity: 1,
        },
        out: {
          opacity: 0,
        },
      },
    pageTransition: {
        duration: 0.5,
      }
}

export const Zoom = {
    pageVariants: {
        initial: {
          scale: 0.9,
          opacity: 0,
        },
        in: {
          scale: 1,
          opacity: 1,
        },
        out: {
          scale: 0.9,
          opacity: 0,
        },
      },
    pageTransition: {
        duration: 0.5,
      }
}

export const Rotate = {
    pageVariants: {
        initial: {
          rotate: -180,
          opacity: 0,
        },
        in: {
          rotate: 0,
          opacity: 1,
        },
        out: {
          rotate: 180,
          opacity: 0,
        },
      },
    pageTransition: {
        duration: 0.5,
      }
}
export const Scale = {
    pageVariants: {
        initial: {
          scale: 0.8,
          opacity: 0,
        },
        in: {
          scale: 1,
          opacity: 1,
        },
        out: {
          scale: 0.8,
          opacity: 0,
        },
    },
    pageTransition: {
        duration: 0.5,
      }
}

export const Flip = {
    pageVariants: {
        initial: {
            rotateY: -180,
            opacity: 0,
        },
        in: {
            rotateY: 0,
            opacity: 1,
        },
        out: {
            rotateY: 180,
            opacity: 0,
        },
    },
    pageTransition: {
        duration: 0.5,
    }
}

export const Bounce = {
    pageVariants: {
        initial: {
          y: -100,
          opacity: 0,
        },
        in: {
          y: 0,
          opacity: 1,
        },
        out: {
          y: -100,
          opacity: 0,
        },
      },
    pageTransition: {
        type: "spring",
        stiffness: 100,
        damping: 10,
      }
}
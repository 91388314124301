import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

interface LoaderProps {
  isMobile: boolean;
}

export const TopProductLoader: React.FC<LoaderProps> = ({ isMobile }) => (
  <Grid item xs={isMobile ? 6 : 3} style={{ maxWidth: isMobile ? 'auto' : '262px', padding: '16px 8px' }}>
    <Card sx={{ boxShadow: 5, borderRadius: '14px', maxHeight: isMobile ? '220px' : '330px' }}>
      <ContentLoader
        speed={2}
        width="100%"
        height={isMobile ? 220 : 330}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height={isMobile ? 117 : 181} />
        <rect x="5%" y={isMobile ? 130 : 200} rx="4" ry="4" width="60%" height="12" />
        <rect x="5%" y={isMobile ? 150 : 220} rx="4" ry="4" width="30%" height="12" />
        <rect x="5%" y={isMobile ? 180 : 260} rx="4" ry="4" width="80%" height="20" />
      </ContentLoader>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="div">
          &nbsp;
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export const ImageLoader: React.FC<LoaderProps> = ({ isMobile }) => (
  <ContentLoader
    speed={2}
    width={isMobile ? '90%' : '1420px'}
    height={isMobile ? '200px' : '500px'}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ display: 'block', objectFit: 'cover', maxWidth: '100%', borderRadius: '14px', marginLeft: isMobile ? '5%' : '13%' }}
  >
    <rect x="0" y="0" rx="14" ry="14" width="100%" height="100%" />
  </ContentLoader>
);

export const CategoryLoader: React.FC<LoaderProps> = ({ isMobile }) => (
  <Box sx={{ position: 'relative', p: 0.5, display: 'inline-block', flexShrink: 0, height: isMobile ? '175px' : '300px', width: isMobile ? '120px' : '220px', borderRadius: '14px', overflow: 'hidden' }}>
    <ContentLoader
      speed={2}
      width={isMobile ? '120px' : '220px'}
      height={isMobile ? '175px' : '300px'}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="14" ry="14" width="100%" height="100%" />
    </ContentLoader>
  </Box>
);

export const HotelsLoader: React.FC<LoaderProps> = ({ isMobile }) => (
  <Box ml={isMobile ? '2%' : '17%'} sx={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', width: isMobile ? '88%' : '62%', maxWidth: isMobile ? '88%' : '62%', justifyContent: isMobile ? 'start' : 'center', height: isMobile ? '125px' : '190px', '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none' }}>
    <ContentLoader
      speed={2}
      height={isMobile ? '86px' : '142px'}
      width={isMobile ? '86px' : '142px'}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="14" ry="14" width="100%" height="100%" />
    </ContentLoader>
  </Box>
);

import { Box, Button, Container, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { primaryColor, secondaryColor } from '../components/Styles/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch } from 'react-redux';
import { handleEditFormdetails, handleFormModal } from '../redux/redux';
import { deleteFreeproduct } from '../apis/AdminApi';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AdminNavbar = (params: any) => {

    const location = useLocation()
    const dispatch = useDispatch()

    const handleSubmit = async () => {
        try {
            const userConfirmed = window.confirm('Are you sure to delete?');
            if (!userConfirmed) {
                return
            }
            const arrayOfIdsToDelete: any[] = []
            params.selectedRow.id.map((data: any) => arrayOfIdsToDelete.push(data._id))

            await deleteFreeproduct(arrayOfIdsToDelete)
            
            params.getFreeProducts()
            params.handleUnselectAll()

        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error)
        }
    }

    const handleEdit = () => {
        dispatch(handleEditFormdetails({visible: true}))
    }

    const getAddText = () => {
        if (location.pathname === "/adminProducts") return "Add Product";
        if (location.pathname === "/adminFreeProducts") return "Add free product";
        if (location.pathname === "/adminCategory") return "Add Category";
        return "";
      };

    return (
        <Box bgcolor={secondaryColor}>
            <Container maxWidth={"xl"} sx={{ width: '100%' }}>
                <Box width={"625px"} height={"100%"} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Link to={"/adminHome"} style={{ marginTop: '2%', width: '147px', padding: '5px', color: location.pathname === "/adminHome" ? '#FFFFFF' : '#4E4E4E', fontFamily: 'Proxima Nova', height: '42px', gap: '0px', borderRadius: '40px', backgroundColor: location.pathname === "/adminHome" ? primaryColor : secondaryColor, opacity: '0px', textDecoration: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        Home
                    </Link>
                    <Link to={"/adminProducts"} style={{ marginTop: '2%', width: '147px', padding: '5px', color: location.pathname === "/adminProducts" ? '#FFFFFF' : '#4E4E4E', fontFamily: 'Proxima Nova', height: '42px', gap: '0px', borderRadius: '40px', backgroundColor: location.pathname === "/adminProducts" ? primaryColor : secondaryColor, opacity: '0px', textDecoration: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        Product
                    </Link>
                    <Link to={"/adminFreeProducts"} style={{ marginTop: '2%', width: '147px', padding: '5px', color: location.pathname === "/adminFreeProducts" ? '#FFFFFF' : '#4E4E4E', fontFamily: 'Proxima Nova', height: '42px', gap: '0px', borderRadius: '40px', backgroundColor: location.pathname === "/adminFreeProducts" ? primaryColor : secondaryColor, opacity: '0px', textDecoration: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        Free Product
                    </Link>
                    <Link to={"/adminCategory"} style={{ marginTop: '2%', width: '147px', padding: '5px', color: location.pathname === "/adminCategory" ? '#FFFFFF' : '#4E4E4E', fontFamily: 'Proxima Nova', height: '42px', gap: '0px', borderRadius: '40px', backgroundColor: location.pathname === "/adminCategory" ? primaryColor : secondaryColor, opacity: '0px', textDecoration: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        Category
                    </Link>
                </Box>
                {location.pathname !== "/adminHome" && <Box textAlign={"start"}>
                    { <Box sx={{ textAlign: 'end', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        { (location.pathname !== "/adminProducts" && location.pathname !== "/adminCategory" && params.deleteIcon && !params?.hasChanges()) &&
                            <DeleteForeverIcon onClick={handleSubmit} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '8%' : '7%', height: '30px', width: '30px', marginTop: -5 }} />
                        }
                        {params.selectedRow.id.length === 1 &&
                            <>
                                {
                                    params.selectedRow.type !== "products" && params.selectedRow.type !== "category" && !params?.hasChanges() && <EditOutlinedIcon onClick={() => handleEdit()} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '10%' : '9%', height: '30px', width: '30px', marginTop: -5 }} />
                                }

                                <KeyboardArrowUpIcon onClick={() => params.moveRow(params.selectedRow.id[0]['id'], 'up')} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '14%' : '13%', height: '30px', width: '30px', marginTop: -5 }} />
                                <KeyboardArrowDownIcon onClick={() => params.moveRow(params.selectedRow.id[0]['id'], 'down')} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '12%' : '11%', height: '30px', width: '30px', marginTop: -5 }} />


                            </>
                        }
                        { ((location.pathname !== "/adminProducts" && location.pathname !== "/adminCategory") || params.selectedRow?.id.length >=1) && <Button
                            variant="contained"
                            id='submit'
                            type="submit"
                            disabled={!params?.hasChanges() && params.selectedRow.id.length >= 1}
                            sx={{
                                height: '40px',
                                //   width: '150px',
                                fontSize: '16px',
                                backgroundColor: primaryColor,
                                ":hover": { backgroundColor: primaryColor },
                                borderRadius: '10px',
                                marginTop: '-3%'
                            }}
                            onClick={() => params?.hasChanges() ? params.updateOrderApi() : dispatch(handleFormModal(true))}
                        >
                            <Typography fontSize={'12px'}>{params?.hasChanges() ? 'Save' : `${getAddText()}`}</Typography>
                        </Button>}
                    </Box>}
                </Box>}
            </Container>
        </Box>
    )
}

export default AdminNavbar
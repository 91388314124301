import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import AdminNavbar from './AdminNavbar';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import Forms from '../forms/Forms';
import { defaultColumns } from '../utils/utils';
import { fetchProductsForAdmin, updateProductsOrderApi } from '../apis/AdminApi';
import EditForm from '../forms/EditForm';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';


const AdminProducts = () => {

  const superuserToken = useSelector((store:any)=> store.superuserToken)
  const editModal = useSelector((store:any)=> store.editFormdetails.visible)
  const modal = useSelector((store: any) => store.openModal)
  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [deleteIcon, setDeleteIcon] = useState(false)
  const [selectedRow, setSelectedRow] = useState({ type: "products", id: [] })
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<any>([]); //To check whether user has updated the order of proucts
  const [loading, setLoading] = useState(false)
  
  const moveRow = (id: any, direction: string) => {
    const index = rows.findIndex((row: any) => row.id === id);
    if (index === -1) return;

    const newRows = [...rows];
    if (direction === 'up' && index > 0) {
      // Swap with the previous row
      [newRows[index], newRows[index - 1]] = [newRows[index - 1], newRows[index]];
    } else if (direction === 'down' && index < newRows.length - 1) {
      // Swap with the next row
      [newRows[index], newRows[index + 1]] = [newRows[index + 1], newRows[index]];
    }
    setRows(newRows);
  };

  const fetchTTDproducts = async () => {
    try {
      setLoading(true)
      const response = await fetchProductsForAdmin()
      if(response.data.length === 0){
        setColumns(defaultColumns)
        setRows([])
        setSelectedRow({ type: "products", id: [] })
        setLoading(false)
        return
      }

      const keys = Object.keys(response.data[0]);
      const fieldsToHide = ['location', '__v','hotel_id','_id','createdAt','updatedAt'];

      const filteredKeys = keys.filter(key => !fieldsToHide.includes(key));
      const generatedColumns: any = [
        {
          field: 'id',
          headerName: 'ID',
          width: 80
        },
        ...filteredKeys.map(key => ({
          field: key,
          headerName: key === '_id' ? 'Product_id' : key.charAt(0).toUpperCase() + key.slice(1) ,
          width: key === 'price' ? 100 : key === 'offer' ? 90 : key === 'order' ? 90 : 150
        })),
        {
          field: 'latitude',
          headerName: 'Latitude',
          width: 120
        },
        {
          field: 'longitude',
          headerName: 'Longitude',
          width: 120
        }
      ];
      setColumns(generatedColumns);

      const generatedRows = response.data.map((row: {
        location: any; _id: any; 
      }, index: any) => ({
        id: index + 1,
        ...row,
        latitude: row.location.latitude,
        longitude: row.location.longitude,
      }))
      setRows(generatedRows);
      setInitialState(generatedRows)
    } catch (error) {
      console.log("🚀 ~ fetchProducts ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const hasChanges = () => {
    if (rows.length !== initialState.length) return true;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]['_id'] !== initialState[i]['_id']) return true;
    }
    return false;
  };

  useEffect(() => {
    if (columns.length === 0) {
      fetchTTDproducts()
    }
  }, [columns])

  const handleChange = (selectionModel: any) =>{
    if (selectionModel.length > 0 && !isEmpty(superuserToken)) setDeleteIcon(true)
    else setDeleteIcon(false)
  
    let objectsTotDelete: any = { type: "products", id: [] }
    selectionModel.forEach((record: any) => {
      objectsTotDelete.id.push(rows[record - 1]);
    });
    setSelectedRow(objectsTotDelete)
  }

  const handleUnselectAll = () => {
    apiRef.current.setRowSelectionModel([]);
    setDeleteIcon(false);  // Optionally reset the delete icon state
    setSelectedRow({ type: "products", id: [] });  // Reset selected rows
  };


  const updateOrderApi = async() => {
    try {
      if(!hasChanges()){
        return
      }
      setLoading(true)
      const newOrder = rows.map((element: any) => element._id);

      const response = await updateProductsOrderApi(newOrder)
      
      alert(response.data)
      fetchTTDproducts()
      handleUnselectAll()
    } catch (error) {
      console.log("🚀 ~ updateOrderApi ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  return (
    <>
      { editModal && <EditForm handleUnselectAll={handleUnselectAll} selectedRow={selectedRow} setColumns={setColumns} columns={columns} formName={"products"}/>}
      { modal && <Forms setColumns={setColumns} columns={columns} formName={"products"}/>}
      <AdminNavbar updateOrderApi={updateOrderApi} hasChanges={hasChanges} moveRow={moveRow} handleUnselectAll={handleUnselectAll} fetchTTDproducts={fetchTTDproducts} selectedRow={selectedRow} deleteIcon={deleteIcon}/>
      <Box sx={{ width: '100vw', height: '80vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '1%' }}>

        <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            loading={loading}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            sx={{
              flexGrow: 1, // Make the DataGrid grow to fill the Container
              width: '100%',
              border: 'none'
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={handleChange}
            disableMultipleRowSelection
          />
        </Container>
      </Box>
    </>
  )
}

export default AdminProducts
import axiosInstance from "../axios/axios";

export const fetchProductsForAdmin = () => axiosInstance.get(`/product/fetch-admin-products`)

export const fetchFreeProductsForAdmin = () => axiosInstance.get(`/free-product/fetch-admin-products`)

export const fetchCategoryForAdmin = () => axiosInstance.get(`/category/fetch-admin-category`)

export const updateHotelDetails = (values: any) => axiosInstance.post('/hotel/update-hotel', values) 

export const addProduct = (values: any, formName: any) => axiosInstance.post(`/product/add-product?formName=${formName}`, values, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
}) 

export const addFreeProduct = (values: any, formName: any) => axiosInstance.post(`/free-product/add-free-product?formName=${formName}`, values, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

export const addCategory = (values: any, formName: any) => axiosInstance.post(`/category/add-category?formName=${formName}`, values, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
}) 

export const deleteProduct = (values: any) => axiosInstance.post('/product/delete-product', {values}) 

export const deleteFreeproduct = (values: any) => axiosInstance.post('/free-product/delete-free-product', {values}) 

export const updateProduct = (values: any) => axiosInstance.post('/product/update-product', values) 

export const updateFreeProduct = (values: any) => axiosInstance.post('/free-product/update-free-product', values) 

export const updateCategory = (values: any) => axiosInstance.post('/category/update-category', values) 

export const updateProductsOrderApi = (values: any) => axiosInstance.post('/product/update-order-product', values) 

export const updateFreeProductsOrderApi = (values: any) => axiosInstance.post('/free-product/update-order-free-product', values) 

export const updateCategoryOrderApi = (values: any) => axiosInstance.post('/category/update-order-category', values) 

export const updateHotelLogo = (values: any) => axiosInstance.post('/hotel/image-update-hotel', values) 
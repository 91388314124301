import './App.css';
import Navbar from './components/Navbar/Navbar';
import { Route, Routes, Navigate, useLocation, BrowserRouter } from 'react-router-dom';
import Home from './components/Views/Home/Home';
import Footer from './components/Footer/Footer';
import TopProducts from './components/Views/TopProducts/TopProducts';
import ProductDetail from './components/Views/TopProducts/ProductDetail';
import AdminProducts from './admin/AdminProducts';
import AdminFreeProducts from './admin/AdminFreeProducts';
import AdminCategory from './admin/AdminCategory';
import AdminHomePage from './admin/AdminHomePage';
import AdminLogin from './admin/AdminLogin';
import NotFound from './components/Views/NotFound';
import { useDispatch, useSelector } from 'react-redux';
import Forms from './forms/Forms';
import { useEffect, useMemo } from 'react';
import { handleEditFormdetails, handleFormModal, handleLogout } from './redux/redux';
import EditForm from './forms/EditForm';
import SuperAdminLogin from './superAdmin/SuperAdminLogin';
import AdminDashboard from './superAdmin/AdminDashboard';
import TripXOXOproductsPage from './superAdmin/TripXOXOproductsPage';
import TripXOXOcategoryPage from './superAdmin/TripXOXOcategoryPage';
import { Zoom } from './utils/utils';
import { AnimatePresence, motion } from 'framer-motion';
function App() {

  const adminToken = useSelector((store: any) => store.adminToken)
  const superuserToken = useSelector((store: any) => store.superuserToken)
  const hotel_id = ""
  const dispatch = useDispatch()
  const search = window.location.search;
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const pageVariants = Zoom.pageVariants

  const pageTransition = Zoom.pageTransition

  useEffect(() => {

    if(params.get("id")){
      localStorage.setItem("hotel_id",params.get("id") || "")
    }
    const handlePageReload = () => {
      // dispatch(handleLogout())
      dispatch(handleFormModal(false))
      dispatch(handleEditFormdetails({visible: false}))
    };

    window.addEventListener('beforeunload', handlePageReload);

    return () => {
      window.removeEventListener('beforeunload', handlePageReload);
    };
  }, [dispatch, params]);
  
  const Main = () => {
    const location = useLocation()

    return(
      <AnimatePresence >
      <Routes location={location} >
        {/* <Route path="/" Component={Home} /> */}
        <Route path="/" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Home />
          </motion.div>
        } />
        {adminToken && (
          <>
            <Route path="/adminLogin" element={<Navigate to="/adminHome" />} />
            <Route path="/adminHome" Component={AdminHomePage} />
            <Route path="/adminProducts" Component={AdminProducts} />
            <Route path="/adminFreeProducts" Component={AdminFreeProducts} />
            <Route path="/adminCategory" Component={AdminCategory} />
            <Route path="/form" Component={Forms} />
            <Route path="/editform" Component={EditForm} />
          </>
        )}
        { superuserToken &&
          <>
            <Route path="/superAdminLogin" element={<Navigate to="/adminDashboard" />} />
            <Route path="/tripXOXOproducts" Component={TripXOXOproductsPage} />
            <Route path="/adminDashboard" Component={AdminDashboard}/>
            <Route path="/tripXOXOcategories" Component={TripXOXOcategoryPage}/>
          </>
        }
        <Route path="/adminLogin" Component={AdminLogin} />
        <Route path="/singleProduct" element={<motion.div
          initial="initial"
          animate="in"
          exit="out"
          key="productDetail"
          variants={pageVariants}
          transition={pageTransition}
        >
          <ProductDetail />
        </motion.div>} />
        <Route path="/topProducts" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            key="topProducts"
            variants={pageVariants}
            transition={pageTransition}
          >
            <TopProducts />
          </motion.div>
        } />
        <Route path="/superAdminLogin" element={<SuperAdminLogin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </AnimatePresence>
    )
  }
  return (
    <BrowserRouter>
        <Navbar />
        <Main />
        {/* {!adminToken && hotel_id !== "" && <Footer />} */}
    </BrowserRouter>
  );
}

export default App;

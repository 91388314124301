import { Box, Container } from '@mui/material'
import { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import Forms from '../forms/Forms'
import { defaultFreeProdsColumns } from '../utils/utils'
import { fetchFreeProductsForAdmin, updateFreeProductsOrderApi } from '../apis/AdminApi'
import { useSelector } from 'react-redux'
import EditForm from '../forms/EditForm'

const AdminFreeProducts = () => {

  const editModal = useSelector((store:any)=> store.editFormdetails.visible)
  const modal = useSelector((store: any) => store.openModal)
  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [deleteIcon, setDeleteIcon] = useState(false)
  const apiRef = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState({ type: "freeproducts", id: [] })
  const [initialState, setInitialState] = useState<any>([]); //To check whether user has updated the order of proucts
  const [loading, setLoading] = useState(false)

  const getFreeProducts = async() => {
    try {
      setLoading(true)
      const response = await fetchFreeProductsForAdmin()
      if(response.data.length === 0){
        setColumns(defaultFreeProdsColumns)
        setRows([])
        setSelectedRow({ type: "freeproducts", id: [] })
        setLoading(false)
        return
      }
      const keys = Object.keys(response.data[0]);
      const fieldsToHide = ['location', '__v','createdAt','updatedAt','hotel_id'];
      const filteredKeys = keys.filter(key => !fieldsToHide.includes(key));

      const generatedColumns: any = [
        {
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        ...filteredKeys.map(key => ({
          field: key,
          headerName: key === '_id' ? 'Product_id' : key.charAt(0).toUpperCase() + key.slice(1),
          width: key === 'price' ? 100 : key === 'offer' ? 70 : key === 'order' ? 70 : 150
        })),
        {
          field: 'latitude',
          headerName: 'Latitude',
          width: 120
        },
        {
          field: 'longitude',
          headerName: 'Longitude',
          width: 120
        }
      ];
      setColumns(generatedColumns);

      const generatedRows = response.data.map((row: {
        location: any; _id: any;
      }, index: any) => ({
        id: index + 1,
        ...row,
        latitude: row.location.latitude,
        longitude: row.location.longitude,
      }))
      setRows(generatedRows);
      setInitialState(generatedRows)

    } catch (error) {
      console.log("🚀 ~ getFreeProducts ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const hasChanges = () => {
    if (rows.length !== initialState.length) return true;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].order !== initialState[i].order) return true;
    }
    return false;
  };

  const handleChange = (selectionModel: any) =>{
    if (selectionModel.length > 0) setDeleteIcon(true)
    else setDeleteIcon(false)

    let objectsTotDelete: any = { type: "freeproducts", id: [] }
    selectionModel.forEach((record: any) => {
      objectsTotDelete.id.push(rows[record - 1]);
    });
    setSelectedRow(objectsTotDelete)

  }

  const handleUnselectAll = () => {
    apiRef.current.setRowSelectionModel([]);
    setDeleteIcon(false);  // Optionally reset the delete icon state
    setSelectedRow({ type: "freeproducts", id: [] });  // Reset selected rows
  };

  const moveRow = (id: any, direction: string) => {
    const index = rows.findIndex((row: any) => row.id === id);
    if (index === -1) return;

    const newRows = [...rows];
    if (direction === 'up' && index > 0) {          // Swap with the previous row

        if(newRows[index]['section_id'] !== newRows[index - 1]['section_id'] ) return

      [newRows[index], newRows[index - 1]] = [newRows[index - 1], newRows[index]];
      [newRows[index]['order'], newRows[index - 1]['order']] = [newRows[index - 1]['order'], newRows[index]['order']];

    } else if (direction === 'down' && index < newRows.length - 1) {        // Swap with the next row

      if(newRows[index]['section_id'] !== newRows[index + 1]['section_id'] ) return

      [newRows[index], newRows[index + 1]] = [newRows[index + 1], newRows[index]];
      [newRows[index]['order'], newRows[index + 1]['order']] = [newRows[index + 1]['order'], newRows[index]['order']];

    }
    setRows(newRows);
  };


  const updateOrderApi = async() => {
    try {
      if(!hasChanges()){
        return
      }
      const updatedOrders = rows.map((row: any) => ({ id: row._id, order: row.order }));
      const newOrder: any[] = []
      updatedOrders.forEach((updatedRow: { order: any; }, index: string | number) => {
        if (updatedRow.order !== initialState[index].order) {
          newOrder.push(updatedRow);
        }
      });

      const response = await updateFreeProductsOrderApi(newOrder)
      
      alert(response.data)
      getFreeProducts()
      handleUnselectAll()
    } catch (error) {
      console.log("🚀 ~ updateOrderApi ~ error:", error)
      
    }
  }

  useEffect(() => {
    if (columns.length === 0) {
      getFreeProducts()
    }
  }, [columns])

  return (
    <>
      { editModal && <EditForm handleUnselectAll={handleUnselectAll} selectedRow={selectedRow} setColumns={setColumns} columns={columns} formName={"freeproducts"}/>}
      { modal && <Forms selectedRow={selectedRow} setColumns={setColumns} columns={columns} formName={"freeproducts"}/>}
      <AdminNavbar updateOrderApi={updateOrderApi} moveRow={moveRow} hasChanges={hasChanges} selectedRow={selectedRow} handleUnselectAll={handleUnselectAll} getFreeProducts={getFreeProducts} deleteIcon={deleteIcon}/>
      <Box sx={{ width: '100vw', height: '80vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '1%' }}>

        <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            loading={loading}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            sx={{
              flexGrow: 1, // Make the DataGrid grow to fill the Container
              width: '100%',
              border: 'none'
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={handleChange}
          />
        </Container>
      </Box>
    </>
  )
}

export default AdminFreeProducts
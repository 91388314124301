// CustomPopup.js
import React from 'react';
import './App.css'

const CustomPopup = ({ onInstallClick, onClose }:any) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <h2>Add to Home Screen</h2>
        </div>
        <div className="popup-body">
          <p>Install this app on your device for quick and easy access.</p>
        </div>
        <div className="popup-footer">
          <button className="install-button" onClick={onInstallClick}>Install</button>
          <button className="close-button" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default CustomPopup;

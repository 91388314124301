import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import AdminNavbar from './AdminNavbar';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import Forms from '../forms/Forms';
import { defaultCategoriesColumns } from '../utils/utils';
import { fetchCategoryForAdmin, updateCategoryOrderApi } from '../apis/AdminApi';
import { useSelector } from 'react-redux';
import EditForm from '../forms/EditForm';

const AdminCategory = () => {

  const editModal = useSelector((store:any)=> store.editFormdetails.visible)
  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [deleteIcon, setDeleteIcon] = useState(false)
  const apiRef = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState({ type: "category", id: [] })
  const [initialState, setInitialState] = useState<any>([]); //To check whether user has updated the order of proucts
  const modal = useSelector((store: any) => store.openModal)
  const [loading, setLoading] = useState(false)

  const getCategories = async () => {
    try {
      setLoading(true)
      const response = await fetchCategoryForAdmin()
      if(response.data.length === 0){
        setColumns(defaultCategoriesColumns)
        setRows([])
        setSelectedRow({ type: "category", id: [] })
        setLoading(false)
        return
      }
      const keys = Object.keys(response.data[0]);

      const filteredKeys = keys.filter(key => key !== "__v" && key !== "hotel_id" && key !== 'updatedAt' && key !== 'assigned_image');

      const generatedColumns: any = [
        {
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        ...filteredKeys.map(key => ({
          field: key,
          headerName: key === '_id' ? 'Category_id' : key.charAt(0).toUpperCase() + key.slice(1),
          width: key === 'order' ? 100 : 150
        })),

      ];
      setColumns(generatedColumns);

      const generatedRows = response.data.map((row: {
      }, index: any) => ({
        id: index + 1,
        ...row,
      }))
      setRows(generatedRows);
      setInitialState(generatedRows)

    } catch (error) {
      console.log("🚀 ~ fetchProducts ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const handleChange = (selectionModel: any) =>{
    if (selectionModel.length > 0) setDeleteIcon(true)
    else setDeleteIcon(false)
    let objectsTotDelete: any = { type: "category", id: [] }
    selectionModel.forEach((record: any) => {
      objectsTotDelete.id.push(rows[record - 1]);
    });
    setSelectedRow(objectsTotDelete)
  }

  const handleUnselectAll = () => {
    console.log('handleUnselectAll')
    apiRef.current.setRowSelectionModel([]);
    setDeleteIcon(false);  // Optionally reset the delete icon state
    setSelectedRow({ type: "category", id: [] });  // Reset selected rows
  };

  const hasChanges = () => {
    if (rows.length !== initialState.length) return true;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]['_id'] !== initialState[i]['_id']) return true;
    }
    return false;
  };

  const updateOrderApi = async () => {
    try {
      if (!hasChanges()) {
        return
      }
      setLoading(true)
      const newOrder = rows.map((element: any) => element._id);

      const response = await updateCategoryOrderApi(newOrder)

      alert(response.data)
      getCategories()
      handleUnselectAll()
    } catch (error) {
      console.log("🚀 ~ updateOrderApi ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const moveRow = (id: any, direction: string) => {
    const index = rows.findIndex((row: any) => row.id === id);
    if (index === -1) return;

    const newRows = [...rows];
    if (direction === 'up' && index > 0) {
      // Swap with the previous row
      [newRows[index], newRows[index - 1]] = [newRows[index - 1], newRows[index]];
    } else if (direction === 'down' && index < newRows.length - 1) {
      // Swap with the next row
      [newRows[index], newRows[index + 1]] = [newRows[index + 1], newRows[index]];
    }
    setRows(newRows);
  };

  useEffect(() => {
    if (columns.length === 0) {
      getCategories()
    }
  }, [columns])

  return (
    <>
      { editModal && <EditForm handleUnselectAll={handleUnselectAll} selectedRow={selectedRow} setColumns={setColumns} columns={columns} formName={"category"}/>}

      { modal && <Forms setColumns={setColumns} columns={columns} formName={"category"}/> }
      <AdminNavbar updateOrderApi={updateOrderApi} hasChanges={hasChanges} moveRow={moveRow} selectedRow={selectedRow} handleUnselectAll={handleUnselectAll} getCategories={getCategories} deleteIcon={deleteIcon} />
      <Box sx={{ width: '100vw', height: '80vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '1%' }}>

        <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            loading={loading}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            sx={{
              flexGrow: 1, // Make the DataGrid grow to fill the Container
              width: '100%',
              border: 'none'
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={handleChange}
            disableMultipleRowSelection
          />
        </Container>
      </Box>
  </>
  )
}

export default AdminCategory
import { Grid, FormControl, Select, MenuItem, TextField, Button, Box, Modal, Slide, IconButton } from '@mui/material'
import { Form, Formik, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { primaryColor } from '../components/Styles/styles'
import { handleEditFormdetails } from '../redux/redux'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { makeStyles } from '@mui/styles'
import { updateProduct, updateFreeProduct, updateCategory } from '../apis/AdminApi'
import JoditEditor from 'jodit-react'
import { fetchCategories } from '../apis/Api'
import { fetchCategoryForSuperAdmin, uploadToS3 } from '../apis/SuperAdminApi'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ClearIcon from '@mui/icons-material/Clear'
import { isEmpty, isString } from 'lodash'

const useStyles = makeStyles({
    helperText: {
        backgroundColor: 'rebeccapurple',
        color: 'blanchedalmond'
    },
})

const EditForm =(params: any) => {

    const styles = useStyles()
    const modal = useSelector((store: any) => store.editFormdetails.visible)
    const formName = params.formName
    const [store, setStore] = useState(modal)
    const [categories, setCategories] = useState([])
    const dispatch = useDispatch()
    const columns = params.columns
    const initialValues: any = {}
    const validationSchema: any = {}
    const columnsToHide = ["id", "_id", "hotel_id", "createdAt", "updatedAt","order","assigned_to"]
    const [imageLink, setImageLink] = useState(params.selectedRow.id[0]?.['image'])
    const finalColumns = columns.filter((key: any) => !columnsToHide.includes(key.field))
    const descField = finalColumns.find((column: any) => column.field === 'description');
    const otherFields = finalColumns.filter((column: any) => column.field !== 'description');
    const filtertedColumns = descField ?  [...otherFields, descField] : otherFields;
    const fileInputRef = useRef<any>(null);
    const formRef = useRef<any>(null)
    const initialImageLink = params.selectedRow.id[0]?.['image']

    filtertedColumns.forEach((column: any) => {
        const value = params.selectedRow.id[0]?.[column.field];
        initialValues[column.field] = (value !== undefined && value !== null) ? value : '';
        if(column.field === "latitude" ){
            validationSchema[column.field] = Yup.string().matches(/^\d+(\.\d+)?$/, 'Invalid latitude format').required('Latitude is required')
        }
        else if(column.field === "longitude" ){
            validationSchema[column.field] = Yup.string().matches(/^\d+(\.\d+)?$/,'Invalid longitude format').required('Longitude is required')
        }
        else if(column.field === 'price' || column.field ===  'offer' || column.field ===  'order'){
            validationSchema[column.field] = Yup.string().matches(/^\d+$/, `Invalid ${column.field} format`).required(`${column.headerName} is required`);
        }
        else if(column.field === 'link' && column.field === 'image' ){
        }
        else{
            validationSchema[column.field] = Yup.string().required(`${column.headerName} is required`);
        }

    });
    const formikValidationSchema = Yup.object().shape(validationSchema);

    const handleSubmit = async(values: any) => {

        values._id = params.selectedRow.id[0]?.["_id"]

        if( (imageLink?.type === 'image/jpeg') || (initialImageLink != imageLink && !imageLink?.includes('amazonaws.com/') && !isEmpty(imageLink))){
            console.log('New image pushed to server')
            const response = await uploadFileToS3(imageLink)
            values.image = response
        }else{
            console.log('No changes in image or entered any manual link')
        }
        const { latitude, longitude, ...filteredValues } = values;
        const updatedValues = {
            ...filteredValues,
            location: {
                latitude: parseFloat(latitude), // Convert latitude to float
                longitude: parseFloat(longitude), // Convert longitude to float
            }
        }
        if (formName === 'products') {      //todo: Currently not using. Need to remove if not using further
            await updateProduct(updatedValues)
        } else if (formName === 'freeproducts') {
            await updateFreeProduct(updatedValues)
        } else if (formName === 'category') {
            await updateCategory(filteredValues)
        } 
        else if (formName === 'tripXOXOproducts') {
            await updateProduct(updatedValues)
        }   
        params.handleUnselectAll()
        params.setColumns([])
        dispatch(handleEditFormdetails({visible: false}))
    }

    useEffect(() => {
        if (store !== modal) {
            setStore(modal)
        }
    }, [modal,store])

    const getCategories = async () => {
        try {
            let response: any
            if(params.selectedRow?.type === "tripXOXOproducts"){
                response = await fetchCategoryForSuperAdmin()
            }
            else{
                response = await fetchCategories()
            }
            setCategories(response.data)
        } catch (error) {
            console.log("🚀 ~ getCategories ~ error:", error)
        }
    }
    
    useEffect(() => {
        if (categories.length === 0 && params.selectedRow?.type !== "freeproducts") {
            getCategories()
        }
    }, [categories])
    
    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const handleCancel =async () => {
        try {
            await formRef.current.setFieldValue('image','')
            setImageLink("")
        } catch (error) {
            console.log("🚀 ~ handleCancel ~ error:", error)
            
        }
    }

    const handleFileChange = async(event: any) => {

        const file = event.target.files[0];
        setImageLink(file)
        formRef.current.setFieldValue('image','')

    };

    const uploadFileToS3 = async (file: any) => {
        try {
            const formData: any = new FormData();

            formData.append('file', file);

            formData.append('key', `uploads/${file.name}`);
            const response = await uploadToS3(formData,formName)
            console.log('File uploaded successfully:', response.data);
            return response.data
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const Formbody = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={formikValidationSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container sx={{ width: '842px', overflowY: 'hidden', height: '100%', marginTop: params?.selectedRow?.type === "freeproducts" ? '35%': 0 }}>
                            <div style={{ width: '100%', marginTop: '2%', display: 'flex', justifyContent: 'space-between' }}>
                                <div className='form-footer-header'>
                                    {params.formName === "products" ? "Add Product" : params.formName === "freeproducts" ? "Add Free Product" : "Add Category"}
                                    <CloseIcon onClick={() => dispatch(handleEditFormdetails({visible: false}))} sx={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            {filtertedColumns.map((data: any, index: any) => (
                                <React.Fragment key={index}>
                                    {data.field === "category" ?
                                        <Grid item xs={12} md={6}>
                                            <div className='form-content'>
                                                Category
                                            </div>
                                            <FormControl fullWidth error={Boolean(formik.touched.category && formik.errors.category)}>
                                                <Select
                                                    className='form-textfield'
                                                    sx={{
                                                        marginTop: '2%',
                                                        width: '370px',
                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                                                        '& .MuiFormHelperText-root': { color: 'red' },
                                                    }}
                                                    id={data.field}
                                                    name={data.field}
                                                    value={formik.values[data.field]}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    {
                                                        categories.map((category: any, index: any) => (
                                                            <MenuItem key={index} value={category.name || ''}>{category.name || ''}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        :
                                        data.field === "image" ?
                                            <>
                                                {
                                                    imageLink ? 
                                                        <Grid item xs={12} md={6}>
                                                            <div className='form-content'>
                                                                Image Selected:
                                                            </div>
                                                            <img src={ isString(imageLink) ? imageLink: URL.createObjectURL(imageLink)} alt="Selected" style={{ height: '100px', width: '100px', marginTop: '2%' }} />
                                                            <IconButton
                                                                color="primary"
                                                                onClick={handleCancel}
                                                                sx={{ marginTop: '-42%', marginLeft: '30%' }}
                                                            >
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    :
                                                    <Grid item xs={12} md={6} >
                                                        <div className='form-content'>
                                                            Image
                                                        </div>
                                                        <TextField
                                                            placeholder="Enter or paste image link"
                                                            // className='form-textfield'
                                                            sx={{
                                                                marginTop: '2%',
                                                                width: '340px',
                                                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                                                                '& .MuiFormHelperText-root': { color: 'red' }
                                                            }}
                                                            id={data.field}
                                                            name={data.field}
                                                            value={formik.values.id}
                                                            onChange={formik.handleChange}
                                                            // onChange={handleFileChange}
                                                            helperText={<ErrorMessage className={styles.helperText} name="image" />}
                                                        />
                                                        <input
                                                            accept="image/*"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                            onChange={handleFileChange}
                                                        />
                                                        <IconButton
                                                            color="primary"
                                                            onClick={handleIconClick}
                                                            sx={{ marginTop: '2%' }}
                                                        >
                                                            <CloudUploadIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={handleCancel}
                                                            sx={{ marginTop: '2%' }}
                                                        >
                                                            {/* <CloudUploadIcon /> */}
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </>
                                            :
                                            data.field === "description" ?
                                                <Grid item xs={12} md={12}>
                                                    <div className='form-content' style={{ marginTop: '2%' }}>
                                                        {data.headerName}
                                                    </div>
                                                    <JoditEditor
                                                        className='joditor'
                                                        value={formik.values.description}
                                                        onChange={(content) => formik.setFieldValue('description', content)}
                                                    /></Grid> :
                                                <Grid item xs={12} md={6}>
                                                    <div className='form-content'>
                                                        {data.headerName}
                                                    </div>
                                                    <TextField placeholder={data.field === "section_id" ? "2, 4 or 5" : ""} className='form-textfield' sx={{ marginTop: '2%', width: '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' }, '& .MuiFormHelperText-root': { color: 'red' } }} id={data.field} name={data.field} value={formik.values[data.field]} onChange={formik.handleChange} helperText={<ErrorMessage className={styles.helperText} name={data.field} />}
                                                    />
                                                </Grid>
                                    }
                                </React.Fragment>
                            ))}
                        </Grid>
                        {/* <Grid container sx={{ justifyContent: 'center', marginTop: '25px' }}> */}
                        <Button color="primary" variant="contained" type="submit" sx={{ margin: '5%', marginLeft: '35%', height: '55px', width: '187px', fontSize: '16px', backgroundColor: primaryColor, ":hover": { backgroundColor: primaryColor } }}>
                            Submit
                        </Button>
                        {/* </Grid> */}
                        <br />
                    </Form>
                )}
            </Formik>
        )
    }
    
  return (
    <Modal
            open={store}
            onClose={() => dispatch(handleEditFormdetails({visible: false}))}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{ backgroundColor: '#ffffff', borderRadius: '8px', maxHeight: '70%', minHeight: '50%', width: '55%', overflowY: 'scroll', marginLeft: '18%', marginTop: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
  )
}

export default EditForm
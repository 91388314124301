import { Box, Button, Container, Typography } from '@mui/material'
import { primaryColor, secondaryColor } from '../components/Styles/styles'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { handleEditFormdetails, handleFormModal } from '../redux/redux'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { deleteCategory, deleteHotels, deleteTpXOXOproducts } from '../apis/SuperAdminApi'

const SuperAdminNavbar = (params: any) => {
    const location = useLocation()
    const dispatch = useDispatch()

    const getAddText = () => {
        if (location.pathname === "/adminDashboard") return "hotel";
        if (location.pathname === "/tripXOXOproducts") return "product";
        if (location.pathname === "/tripXOXOcategories") return "category";
        return "";
    };

    const handleSubmit = async () => {
        try {
            const userConfirmed = window.confirm('Are you sure to delete?');
            if (!userConfirmed) {
                return
            }
            const arrayOfIdsToDelete: any[] = []
            params.selectedRow.id.map((data: any) => arrayOfIdsToDelete.push(data._id))
            console.log('params.selectedRow.type', params.selectedRow.type)
            switch (params.selectedRow.type) {

                case "hotel":
                    await deleteHotels(arrayOfIdsToDelete)
                    params.fetchHotelAccounts()
                    params.handleUnselectAll()
                    return

                case "tripXOXOproducts":
                    await deleteTpXOXOproducts(arrayOfIdsToDelete)
                    params.fetchProducts()
                    params.handleUnselectAll()
                    return

                case "tripXOXOcategory":
                    await deleteCategory(arrayOfIdsToDelete)
                    params.fetchCategories()
                    params.handleUnselectAll()
                    return

                default:
                    return
            }
        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error)
        }
    }
    const handleEdit = async () => {
        try {
            if(params.selectedRow.type !== "tripXOXOcategory"){
                dispatch(handleEditFormdetails({ visible: true }))
            }else{
                params.setEditModeForCategory(true)
            }
        } catch (error) {
            console.log("🚀 ~ handleEdit ~ error:", error)
        }
    }
    return (
        <Box bgcolor={secondaryColor}>
            <Container maxWidth={"xl"} sx={{ width: '100%' }}>
                <Box width={"625px"} height={"50px"} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to={"/adminDashboard"} style={{ marginTop: '8%', width: '147px', padding: '5px', color: location.pathname === "/adminDashboard" ? '#FFFFFF' : '#4E4E4E', fontFamily: 'Proxima Nova', height: '42px', gap: '0px', borderRadius: '40px', backgroundColor: location.pathname === "/adminDashboard" ? primaryColor : secondaryColor, opacity: '0px', textDecoration: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        Hotels
                    </Link>
                    <Link to={"/tripXOXOproducts"} style={{ marginTop: '8%', width: '147px', padding: '5px', color: location.pathname === "/tripXOXOproducts" ? '#FFFFFF' : '#4E4E4E', fontFamily: 'Proxima Nova', height: '42px', gap: '0px', borderRadius: '40px', backgroundColor: location.pathname === "/tripXOXOproducts" ? primaryColor : secondaryColor, opacity: '0px', textDecoration: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        TripXOXO Products
                    </Link>
                    <Link to={"/tripXOXOcategories"} style={{ marginTop: '8%', width: '147px', padding: '5px', color: location.pathname === "/tripXOXOcategories" ? '#FFFFFF' : '#4E4E4E', fontFamily: 'Proxima Nova', height: '42px', gap: '0px', borderRadius: '40px', backgroundColor: location.pathname === "/tripXOXOcategories" ? primaryColor : secondaryColor, opacity: '0px', textDecoration: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        Category
                    </Link>
                </Box>
                <Box textAlign={"start"}>
                    <Box sx={{ textAlign: 'end', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        {params.deleteIcon && !params?.hasChanges() &&
                            <DeleteForeverIcon onClick={handleSubmit} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '8%' : '7%', height: '30px', width: '30px', marginTop: -1 }} />
                        }
                        {params.selectedRow?.id.length === 1 && params.selectedRow.type !== "hotel" && <>
                            <EditOutlinedIcon onClick={() => handleEdit()} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '10%' : '9%', height: '30px', width: '30px', marginTop: -1 }} />
                            {/* <KeyboardArrowUpIcon onClick={()=>params.moveRow(params.selectedRow.id[0]['id'], 'up')} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '14%' : '13%', height: '30px', width: '30px', marginTop: -1 }} />
                        <KeyboardArrowDownIcon onClick={()=>params.moveRow(params.selectedRow.id[0]['id'], 'down')} htmlColor='#57BC6E' sx={{ cursor: 'pointer', position: 'absolute', marginRight: location.pathname === '/adminFreeProducts' ? '12%' : '11%', height: '30px', width: '30px', marginTop: -1 }} /> */}
                        </>
                        }
                        {params.selectedRow.type === "hotel" && params.selectedRow.id.length === 1 && <> <Button
                            variant="contained"
                            id='submit'
                            type="submit"
                            disabled={params.selectedRow?.id.length > 1}
                            sx={{
                                height: '40px',
                                //   width: '150px',
                                fontSize: '16px',
                                backgroundColor: primaryColor,
                                ":hover": { backgroundColor: primaryColor },
                                borderRadius: '10px',
                                marginTop: -2,
                                marginRight: '2%'
                            }}
                            onClick={() => params.setEditMode({ state: true, dataType: 'products' })}
                        >
                            <Typography fontSize={'12px'}>Add/Edit Products</Typography>
                        </Button>
                            <Button
                                variant="contained"
                                id='submit'
                                type="submit"
                                disabled={params.selectedRow?.id.length > 1}
                                sx={{
                                    height: '40px',
                                    //   width: '150px',
                                    fontSize: '16px',
                                    backgroundColor: primaryColor,
                                    ":hover": { backgroundColor: primaryColor },
                                    borderRadius: '10px',
                                    marginTop: -2,
                                    marginRight: location.pathname === '/hotel' ? '14%' : '13%',
                                }}
                                onClick={() => params.setEditMode({ state: true, dataType: 'category' })}
                            >
                                <Typography fontSize={'12px'}>Add/Edit Categories</Typography>
                            </Button></>}
                        <Button
                            variant="contained"
                            id='submit'
                            type="submit"
                            disabled={!params?.hasChanges() && params.selectedRow?.id.length>=1}
                            sx={{
                                height: '40px',
                                //   width: '150px',
                                fontSize: '16px',
                                backgroundColor: primaryColor,
                                ":hover": { backgroundColor: primaryColor },
                                borderRadius: '10px',
                                marginTop: '-15px'
                            }}
                            onClick={() => params?.hasChanges() ? params.updateOrderApi() : dispatch(handleFormModal(true))}
                        >
                            <Typography fontSize={'12px'}>{params?.hasChanges() ? 'Save' : `Add ${getAddText()}`}</Typography>
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
  )
}

export default SuperAdminNavbar
import { Box, Container, Drawer, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import applogo from '../../assests/images/applogo.svg'
import applogoWeb from '../../assests/images/applogoWeb.svg'
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { primaryColor } from '../Styles/styles';
import { useDispatch } from 'react-redux';
import { handleLogout } from '../../redux/redux';

const useStyles = makeStyles({
    fullList: {
        width: '100vw',
        height: '100vh',
    },
})
const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const pathNameToHide = ["/","/adminLogin","/superAdminLogin","/singleProduct","/topProducts"]
    const applogoToShow: any = localStorage.getItem('applogo')
    // const [drawerOpen, setDrawerOpen] = useState(false);
    // const classes = useStyles();
    // const toggleDrawer = (open: any) => (event: any) => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //       return;
    //     }
    //     setDrawerOpen(open);
    //   };
    const logOut = () => {
        dispatch(handleLogout())
        navigate("/adminLogin")
    }
    return (
        <Box py={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: isMobile ? 'auto' : '71px' }}>
            <Container maxWidth={'xl'} sx={{ overflow: 'hidden', padding: isMobile ? '0 16px' : '0 24px', width: '100%' }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={ applogoToShow == "default" ? applogo : applogoToShow || applogo} alt="logo" style={{ width: '100%', height: isMobile ? '45px' : '80px', padding: !isMobile ? '30px' : 0 }} />
                        </Link>
                    </Grid>
                    <Grid item>
                        { !pathNameToHide.includes(location.pathname) &&  <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <IconButton onClick={logOut}>
                            {/* <IconButton onClick={toggleDrawer(true)}> */}
                                <LogoutIcon htmlColor={primaryColor}/>
                            </IconButton>
                        </Box>}
                        {/* <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                            <Box
                                className={classes.fullList}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}

                            >
                                <Box sx={{ boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', height: '80px', borderColor: '#FFFFFF', borderRadius: 2, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <IconButton onClick={toggleDrawer(true)}>
                                        <img src={applogo} alt="logo" style={{ width: '100%', height: '45px', marginLeft: '20px' }} />
                                    </IconButton>
                                    <IconButton onClick={toggleDrawer(true)} sx={{ margin: '20px' }}>
                                        <CloseIcon color={'action'} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Drawer> */}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Navbar
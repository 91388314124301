import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ProductDetail = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const { state } = location || {};
    const [load, setload] = useState(false)
    useEffect(()=>{
      setTimeout(()=>{
        setload(true)
      },50)
    },[])
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        { load && <> <img src={state.image} height={isMobile ? '173px' : '500px'} width={isMobile ? '88%' : '1420px'} alt='THATISM_New' style={{  maxWidth: '100%', height: isMobile ? '173px' : '500px', objectFit: 'cover', borderRadius: '14px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', width: isMobile ? '90%' : '70%', height: '5%', alignSelf: 'center' }}>
              <Typography fontSize={'18px'} fontWeight={700} variant='h1' lineHeight={'29px'} sx={{ marginLeft: '10px', marginTop: '10px' }} >{state.free_product_name}</Typography>
              <Typography fontSize={'18px'}   lineHeight={'29px'} sx={{ marginLeft: '10px', marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: state.description }} />
          </div>
          <br/></>}
    </Box>
  )
}

export default ProductDetail
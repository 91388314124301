import { useEffect, useRef, useState } from 'react'
import SuperAdminNavbar from './SuperAdminNavbar'
import { Box, Button, Container, Grid, IconButton, Modal, Slide, TextField } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import Forms from '../forms/Forms'
import { useSelector } from 'react-redux'
import { fetchTripXOXOcategories, uploadToS3 } from '../apis/SuperAdminApi'
import CloseIcon from '@mui/icons-material/Close'
import { primaryColor } from '../components/Styles/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { updateCategory, updateProduct } from '../apis/AdminApi'

const TripXOXOcategoryPage = () => {

  const apiRef = useGridApiRef();
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [deleteIcon, setDeleteIcon] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>({ type: "tripXOXOcategory", id: [] })
  const modal = useSelector((store: any) => store.openModal)
  const [editModeForCategory, setEditModeForCategory] = useState(false)
  const [assignedImage, setAssignedImage] = useState<any>([]);
  // const editModal = useSelector((store:any)=> store.editFormdetails.visible)
  const [loading, setLoading] = useState(false)
  // const [initialState, setInitialState] = useState<any>([]); //To check whether user has updated the order of proucts
  const fileInputRef = useRef<any>(null);
  const hasChanges = () => { return false }
  const handleUnselectAll = () => {
    apiRef.current.setRowSelectionModel([]);
    setDeleteIcon(false);  // Optionally reset the delete icon state
    setSelectedRow({ type: "tripXOXOcategory", id: [] });  // Reset selected rows
  };

  const handleChange = (selectionModel: any) => {
    if (selectionModel.length > 0) setDeleteIcon(true)
    else setDeleteIcon(false)
    let objectsTotDelete: any = { type: "tripXOXOcategory", id: [] }
    selectionModel.forEach((record: any) => {
      objectsTotDelete.id.push(rows[record - 1]);
    });
    console.log('objectsTotDelete', objectsTotDelete)
    setSelectedRow(objectsTotDelete)
    if(objectsTotDelete.id.length > 0){
      setAssignedImage(objectsTotDelete.id[0].assigned_to)
    }else{
      setAssignedImage([])
    }
  }

  const fetchCategories = async () => {
    try {
      setLoading(true)
      const response = await fetchTripXOXOcategories()
      if (response.data.length === 0) {
        // setColumns(superAdminProductsDefaultColumn)
        setRows([])
        setSelectedRow({ type: "tripXOXOcategory", id: [] })
        setLoading(false)
        return
      }
      const keys = Object.keys(response.data[0]);
      const fieldsToHide = ['createdAt', 'updatedAt'];
      const filteredKeys = keys.filter(key => !fieldsToHide.includes(key));
      const generatedColumns: any = [
        {
          field: 'id',
          headerName: 'ID',
          width: 80
        },
        ...filteredKeys.map(key => ({
          field: key === 'assigned_image' ? 'image': key,
          headerName: key === '_id' ? 'Category id' : key.charAt(0).toUpperCase() + key.slice(1),
          width: 150
        })),
      ];
      setColumns(generatedColumns);
      const generatedRows = response.data.map((row: {
        _id: any; assigned_to: any
      }, index: any) => ({
        id: index + 1,
        ...row,
        assigned_to: row.assigned_to.map((e: any) => e)
      }))
      setRows(generatedRows);
      // setInitialState(generatedRows)
    } catch (error) {
      console.log("🚀 ~ fetchCategories ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const uploadFileToS3 = async (file: any) => {
    try {

      const formData: any = new FormData();
      const formName = "tripXOXOcategory"
      formData.append('file', file);
      const response = await uploadToS3(formData, formName)
      console.log('File uploaded successfully:', response.data);
      return response.data

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileChange = async (event: any, index: any) => {
    try {
      const file = event.target.files[0];

      const response = await uploadFileToS3(file)

      setSelectedRow((previousRow: any) => {
        const updatedAssignedImages = previousRow.id[0].assigned_image.map((imageData: any, idx: number) => {
          if (idx === index) {
            return { ...imageData, image: response }; // Update the image URL
          } else {
            return imageData;
          }
        });
    
        return {
          ...previousRow,
          id: [{ ...previousRow.id[0], assigned_image: updatedAssignedImages }]
        };
      });

    } catch (error) {
      console.log("🚀 ~ handleFileChange ~ error:", error)
    }
  };
  
  const handleImageChange = (event: any, index: any) => {
    setSelectedRow((previousRow: any) => {
      const updatedAssignedImages = previousRow.id[0].assigned_image.map((imageData: any, idx: number) => {
        if (idx === index) {
          return { ...imageData, image: event.target.value }; // Update the image URL
        } else {
          return imageData;
        }
      });
  
      return {
        ...previousRow,
        id: [{ ...previousRow.id[0], assigned_image: updatedAssignedImages }]
      };
    });

  };
  
  const handleNameChange = (event: any) => {
    try {

      setSelectedRow((previousRow: any) => ({
        ...previousRow,
        id: [{ ...previousRow.id[0], name: event.target.value }]
      }));
    } catch (error) {
      console.log("🚀 ~ handleNameChange ~ error:", error)
    }
  }

  const handleSubmit = async() => {
    try {
      const updatedValues = {
        ...selectedRow.id[0],
      }
      await updateCategory(updatedValues)
      alert("Data updated successfully")
      handleUnselectAll()
      setColumns([])
      setEditModeForCategory(false)
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)
    }
  }
  useEffect(() => {
    if (columns.length === 0) {
      fetchCategories()
    }
  }, [columns])

  const filteredColumns = columns.filter((col: any) => col.field !== 'assigned_to' && col.field !== 'image');

  return (
    <>
      <SuperAdminNavbar setEditModeForCategory={setEditModeForCategory} hasChanges={hasChanges} handleUnselectAll={handleUnselectAll} selectedRow={selectedRow} deleteIcon={deleteIcon} fetchCategories={fetchCategories} />
      {modal && <Forms setColumns={setColumns} columns={columns} formName={"tripXOXOcategory"} selectedRow={selectedRow} />}
      <Box sx={{ width: '100vw', height: '82vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '1%' }}>
        <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column', marginTop: '1%' }}>
          <DataGrid
            loading={loading}
            apiRef={apiRef}
            rows={rows}
            columns={filteredColumns}
            sx={{
              flexGrow: 1, // Make the DataGrid grow to fill the Container
              width: '100%',
              border: 'none'
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={handleChange}
          />
          {editModeForCategory && <Modal
            open={editModeForCategory}
            onClose={() => setEditModeForCategory(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Slide direction="up" in={editModeForCategory} mountOnEnter unmountOnExit>

              <Grid container sx={{ width: '942px', maxHeight: '70%', minHeight: '50%', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'scroll', borderRadius: '8px' }}>
                <div style={{ width: '100%', marginTop: '2%', display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }}>
                  <div className='form-footer-header' style={{ marginLeft: '2%' }}>
                    Edit Categories
                    <CloseIcon onClick={() => setEditModeForCategory(false)} sx={{ cursor: 'pointer' }} />
                  </div>
                </div>
                <Grid item xs={12} ml={5}>
                  <div className='form-content' style={{ marginTop: 0 }}>
                    Category Name
                  </div>
                  <TextField
                    placeholder="Enter or paste image link"
                    className='form-textfield'
                    sx={{
                      width: '340px',
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                      '& .MuiFormHelperText-root': { color: 'red' }
                    }}
                    // id={data.field}
                    // name={data.field}
                    value={selectedRow.id[0].name}
                    onChange={(e: any)=>handleNameChange(e)}
                  />
                </Grid>
                {assignedImage.map((data: any, index: any) => (
                  <Grid item xs={6} md={12} key={index} width={'90%'} ml={5}>
                    <div className='form-content'>
                      Hotel Name
                    </div>
                    <TextField
                      placeholder="Enter or paste image link"
                      disabled
                      className='form-textfield'
                      sx={{
                        width: '340px',
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                        '& .MuiFormHelperText-root': { color: 'red' }
                      }}
                      // id={data.field}
                      // name={data.field}
                      value={data}
                    // onChange={()=>console.log('first')}
                    />
                    <TextField
                      placeholder="Enter or paste image link"
                      className='form-textfield'
                      sx={{
                        width: '340px',
                        marginLeft: '5%',
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                        '& .MuiFormHelperText-root': { color: 'red' }
                      }}
                      id={`${index}`}
                      name={`${index}`}
                      value={selectedRow?.id[0]?.['assigned_image']?.[index]?.['image']}
                      onChange={(e) => handleImageChange(e, index)}
                    // onChange={handleFileChange}
                    // helperText={<ErrorMessage className={styles.helperText} name="image" />}
                    />
                    <input
                      accept="image/*"
                      type="file"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={(e) => {
                        console.log('index', index)
                        handleFileChange(e, index)}
                      }
                    />
                    <IconButton
                      color="primary"
                      onClick={handleIconClick}
                      sx={{ marginLeft: '2%' }}
                    >
                      <CloudUploadIcon />
                    </IconButton>
                    {/* <IconButton
                      color="primary"
                      onClick={handleCancel}
                    >
                      <ClearIcon />
                    </IconButton> */}
                  </Grid>
                ))}
                <Grid item xs={6} sx={{ visibility: 'hidden' }} />
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                  <Button
                    id='button'
                    // disabled={buttondisabled}
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{ height: '45px', width: '160px', fontSize: '16px', backgroundColor: primaryColor, ":hover": { backgroundColor: primaryColor } }}
                  >
                    Save changes
                  </Button>
                </Grid>
              </Grid>

            </Slide>
          </Modal>}
        </Container>
      </Box>
    </>
  )
}

export default TripXOXOcategoryPage
import { Box, Button, Container, Grid, IconButton, TextField, Typography } from '@mui/material';
import AdminNavbar from './AdminNavbar';
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import './admin.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { primaryColor } from '../components/Styles/styles';
import { updateHotelDetails, updateHotelLogo } from '../apis/AdminApi';
import { handleAdminDetails } from '../redux/redux';
import Vector from '../assests/images/Vector.png';
import { Link } from 'react-router-dom';
import { uploadToS3 } from '../apis/SuperAdminApi';
import applogo from '../assests/images/applogo.svg';
import EditIcon from '@mui/icons-material/Edit';
import { isEmpty } from 'lodash';

type Field = {
  id: keyof FormValues;
  name: string;
  type: string;
};

type FormValues = {
  hotel_name: string;
  username: string;
  bannerHeader: string;
  bannerSubHeader: string;
  image: string;
  address: string;
  section1: string;
  section2: string;
  section3: string;
  section4: string;
  section5: string;
  applogo: string;
};

const AdminHomePage = () => {

  const dispatch = useDispatch()
  const fileInputRef = useRef<any>(null);
  const hotelDetails = useSelector((store: any) => store.adminDetails)
  const [imageLink, setImageLink] = useState<any>(hotelDetails.applogo)
  const fields: Field[] = [
    { id: "hotel_name", name: "Hotel Name", type: "text" },
    { id: "username", name: "Username", type: "text" },
    { id: "bannerHeader", name: "Banner Header", type: "text" },
    { id: "bannerSubHeader", name: "Banner Sub-Header", type: "text" },
    { id: "image", name: "Home Page Banner", type: "image" },
    { id: "address", name: "Hotel Address", type: "text" },
    { id: "section1", name: "Section title -1", type: "text" },
    { id: "section2", name: "Section title -2", type: "text" },
    { id: "section3", name: "Section title -3", type: "text" },
    { id: "section4", name: "Section title -4", type: "text" },
    { id: "section5", name: "Section title -5", type: "text" },
    { id: "applogo", name: "App logo", type: "text" },
  ]
  const updatedValues: FormValues = fields.reduce((values, field) => {

    if (field.id.startsWith('section')) {
      const sectionNumber = field.id.replace('section', '');
      values[field.id] = hotelDetails.section_title[sectionNumber] || '';
    } else {
      values[field.id] = hotelDetails[field.id] || '';
    }
    return values;
  }, {} as FormValues);

  const [updatedFormValues, setupdatedFormValues] = useState<FormValues>(updatedValues)
  const [isUpdated, setIsUpdated] = useState(false)
  const formikValidationSchema: any = {}
  fields.forEach(element => {
    formikValidationSchema[element.id] = Yup.string().required(`${element.name} is required`)
  })
  const validationSchema = Yup.object().shape(formikValidationSchema);
  const [updatedImage, setUpdatedImage] = useState(false)

  const handleSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    try {

      if(!isUpdated){
        return
      }
      if(updatedImage){
        console.log('updating image')
        const uploadImage = await uploadFileToS3(imageLink)
        values.applogo = uploadImage
      }
      const response = await updateHotelDetails(values)
      setIsUpdated(false)
      setUpdatedImage(false)
      setImageLink('')
      dispatch(handleAdminDetails(response.data))
      alert("Data updated successfully")
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    // setInitialValues((values)=>({...values,[name]: value}))
    setupdatedFormValues({
      ...updatedFormValues,
      [name]: value,
    });
  };

  const changeToDefaultImage = async () => {
    try {

      setUpdatedImage(false)
      setImageLink(hotelDetails.applogo)

    } catch (error) {
      console.log("🚀 ~ handleCancel ~ error:", error)

    }
  }

  const handleFileChange = async (event: any) => {

    try {
      const file = event.target.files[0];

      setImageLink(file)
      setUpdatedImage(true)
      setIsUpdated(true)

    } catch (error) {
      console.log("🚀 ~ handleFileChange ~ error:", error)

    }
  };

  const uploadFileToS3 = async (file: any) => {
    try {
      const formData: any = new FormData();

      formData.append('file', file);
      // return
      let formName = 'AppLogo'
      const response = await uploadToS3(formData, formName)
      console.log('File uploaded successfully:', response.data);

      return response.data
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {

    const initialValuesString = JSON.stringify(updatedValues);
    const updatedFormValuesString = JSON.stringify(updatedFormValues);

    if (initialValuesString === updatedFormValuesString) {
      setIsUpdated(false)
    } else {
      setIsUpdated(true)
    }
  }, [updatedFormValues])

  useEffect(() => {
    if(isEmpty(imageLink)){
      setImageLink(hotelDetails.applogo)
    }
  }, [hotelDetails])

  return (
    <>
      <AdminNavbar />
      <Box sx={{ width: '100vw', height: '80vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '1%' }}>
      <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'row', overflowY: 'scroll', alignItems: 'flex-start' }}>
        <Formik
              initialValues={updatedValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik: FormikProps<FormValues>) => (
                <Form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}>
                  <p className='form-content' style={{ width: '100%' }}>APP url: <Link to={`https://experienceqr.tripxoxo.com/?id=${hotelDetails._id}`} target='_blank' >https://experienceqr.tripxoxo.com/?id={hotelDetails._id}</Link></p>
                  {fields.map((item) => (
                    <Grid container key={item.name} direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <div className='form-content'>
                          {item.name}
                        </div>
                      </Grid>
                      <Grid item>
                        {item.id === 'applogo' ?

                          <Grid item width={'100%'}  container direction={"row"} alignItems={"center"} height={'100%'} mt={2}>
                            <Box display="flex" alignItems="center" sx={{ objectFit: 'cover' }} >
                              <input
                                accept="image/*"
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                              />
                              <img
                                src={imageLink === "default" ? applogo: ( updatedImage ? URL.createObjectURL(imageLink): imageLink)}
                                alt="Selected"
                                style={{ height:imageLink === "default" ? '100%' : '75px', width: imageLink === "default" ? '100%' : '75px', marginTop: '2%' }}
                              />
                              <IconButton
                                color="primary"
                                onClick={handleIconClick}
                                sx={{ marginLeft: '8px' }} // Adjust spacing as needed
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                            {updatedImage && <Typography onClick={changeToDefaultImage} sx={{ cursor: 'pointer' }} color={'blue'} fontSize={'12px'} m={1} ml={5}>change it to default ?</Typography>}
                          </Grid>
                          : <TextField
                            sx={{
                              marginTop: '2%',
                              width: '370px',
                              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                              '& .MuiFormHelperText-root': { color: 'red' },
                              borderRadius: '10px',
                            }}
                            id={item.id}
                            name={item.id}
                            value={formik.values[item.id]}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                            helperText={<ErrorMessage name={item.name} />}
                          />
                        }
                      </Grid>
                    </Grid>
                  ))}<br/>
                  <div style={{ textAlign: 'end' , marginRight: '-100px' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!isUpdated}
                      sx={{
                        height: '55px',
                        width: '187px',
                        fontSize: '16px',
                        backgroundColor: primaryColor,
                        ":hover": { backgroundColor: primaryColor },
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
        </Formik>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
            <img src={Vector} alt='vector' />
          </Box>
      </Container>
      </Box>
    </>
  )
}

export default AdminHomePage
import './home.css'
import { Box, Card, CardContent, CardMedia, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { primaryColor } from '../../Styles/styles'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useEffect, useState } from 'react'
import { fetchCategories, fetchFreeProducts, getHomePageBanner, getTopFourProds } from '../../../apis/Api'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import CustomPopup from '../../../CustomPopup'
import { TopProductLoader, ImageLoader, CategoryLoader, HotelsLoader } from '../../../utils/loader'
import defaultCategory from '../../../assests/images/category.jpg'

const useStyles = makeStyles({
  cardHeader: { fontFamily: 'Montserrat', fontSize: '32px', fontWeight: '600', lineHeight: '29px' },
  cardHeader2: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    // fontSize: 8,
    lineHeight: '29px'
  }
})
declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}
const Home = () => {

  const styles = useStyles()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [Ttdtop4Prods, setTtdtop4Prods] = useState([])
  const [categories, setCategories] = useState([])
  const [freeProds, setfreeProds] = useState([])
  const [hotelData, setHotelData] = useState<any>({})
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false)
  const hotel_id = localStorage.getItem('hotel_id')

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: { outcome: string; }) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowPopup(false);
      });
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const fetchTop4Products = async () => {
    try {
      setLoading(true)
      const response = await getTopFourProds()
      setTtdtop4Prods(response.data)
    } catch (error) {
      console.log("🚀 ~ fetchTop4Products ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetchCategories()
      setCategories(response.data)
    } catch (error) {
      console.log("🚀 ~ getCategories ~ error:", error)
    }
  }

  const fetchFTDproducts = async () => {
    try {
      const response = await fetchFreeProducts()
      console.log("🚀 ~ fetchFTDproducts ~ response:", response.data.length)
      setfreeProds(response.data)
    } catch (error) {
      console.log("🚀 ~ fetchFTDproducts ~ error:", error)
    }
  }

  const fetchHomePageBanner = async(hotel_id: any) => {
    try {

        const value = { type: "home", hotel_id}
        const response = await getHomePageBanner(value)
        setHotelData(response.data)
        localStorage.setItem('applogo',response.data.applogo)
    } catch (error) {
      console.log("error",error)
    }
  }

  useEffect(()=>{
    if(isEmpty(hotelData) && !isEmpty(hotel_id)){
      fetchHomePageBanner(hotel_id)
    }
  },[hotel_id])
  
  useEffect(() => {
    if (Ttdtop4Prods.length === 0) {
      fetchTop4Products()
    }
    if (categories.length === 0) {
      getCategories()
    }
    if (freeProds.length === 0) {
      fetchFTDproducts()
    }
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Show your custom install prompt
      setShowPopup(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [])

  return (
    <Box>
      {showPopup && (
        <CustomPopup onInstallClick={handleInstallClick} onClose={handleClosePopup} />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 0, width: '100%', height: isMobile ? '367px' : '478px', objectFit: 'cover', backgroundImage: `url(${hotelData?.image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
        <Box sx={{ zIndex: 1, top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(360deg, #57BC6E 2.42%, rgba(255, 0, 0, 0) 25.85%)' }} />
        <Container maxWidth={'xl'} sx={{ marginLeft: isMobile ? '16px' : '12%', zIndex: 2, bgcolor: 'black' }} className='home-banner-content'>
          <p className='banner-header' >{ hotelData?.bannerHeader}</p>
          <p className='banner-desc' >{ hotelData?.bannerSubHeader}</p>
        </Container>
      </Box>
      {Ttdtop4Prods.length > 0 && <Typography fontSize={'18px'} fontWeight={700} variant='h1' lineHeight={'29px'} sx={{ marginLeft: isMobile ? '6%' : '16%', marginTop: '10px' }} >{ hotelData?.section_title?.["1"] || 'Top Things To Do'}</Typography> }
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Grid width={isMobile ? '100%' : '70%'} container m={2} mt={0} >
          {loading
            ? Array.from(new Array(4)).map((_, index) => (
              <TopProductLoader key={index} isMobile={isMobile} />
            ))
            : Ttdtop4Prods.map((data: any, index) => (
            <Grid maxWidth={isMobile ? 'auto' : '262px'} py={2} px={1.3} xs={isMobile ? 6 : 3} key={index} >
              <Card sx={{ boxShadow: 5, borderRadius: '14px', maxHeight: isMobile ? '220px' : '330px' }} onClick={() => window.open(data.link, "_blank")}>
                <CardMedia
                  sx={{ height: isMobile ? '117px' : '181px' }}
                  image={data?.image}
                  title="image"
                />
                <CardContent sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Typography
                    fontSize={isMobile ? '12px' : '14px'}
                    fontWeight={700}
                    className={styles.cardHeader}
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {data?.product_name}
                  </Typography>
                  <Typography mt={3} fontSize={'12px'} className={styles.cardHeader2}>Starting from</Typography>
                  <Typography fontSize={'14px'} fontWeight={700} className={styles.cardHeader}>INR {data?.price}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <br />
      {Ttdtop4Prods.length > 0 && <div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Link
          to="/topProducts"
          // onClick={handleShowMore}
          state={{homePageBanner: hotelData.image}}
          style={{ backgroundColor: '#ffffff', height: '35px', width: '358px', borderWidth: '1px', borderStyle: 'solid', borderColor: primaryColor, color: primaryColor, borderRadius: '53px', textDecoration: 'none', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
        >
          Find more
        </Link>
      </div>}
        <br/><br/>
      { freeProds.length > 0 && <Typography fontSize={'18px'} fontWeight={700} variant='h1' lineHeight={'29px'} sx={{ marginLeft: isMobile ? '6%' : '16%', marginTop: '10px' }} >{ hotelData?.section_title?.["2"] || 'Free Things To Do'}</Typography> }
      { loading ? 
        Array.from(new Array(1)).map((_, index) => (
          <ImageLoader key={index} isMobile={isMobile} />
        ))
        : <Box sx={{ marginTop: '1%', marginLeft: '20px'}}>
        {freeProds.filter((value: any) => value.section_id === 2).length > 1 ? (
          <Slider {...settings1} slidesToShow={1}>
            {freeProds.map((value: any, index) => value.section_id === 2 && (
              <Link to={"/singleProduct"} state={value} key={index}>
                <img  src={value.image}  height={isMobile ? '200px' : '500px'}  width={isMobile ? '93%' : '1420px'}  alt='londondrycleaners'  style={{ maxWidth: '100%', objectFit: 'cover', borderRadius: '14px', marginLeft: isMobile ? '1%' : '13%'  }}/>
              </Link>
            ))}
          </Slider>
        ) : (
          <div>
            {freeProds.filter((value: any) => value.section_id === 2).map((value: any) => (
              <Link to={"/singleProduct"} state={value} key={value.id}>
                <img key={value.id} src={value.image} height={isMobile ? '190px' : '500px'} width={isMobile ? '380px' : '1420px'} alt='londondrycleaners ' style={{ display: 'block', maxWidth: '100%', objectFit: 'cover', borderRadius: '14px', marginLeft: isMobile ? '0' : '13%', marginBottom: '10px', }} />
              </Link>
            ))}
          </div>
        )}
      </Box>}
      <br/>
      { categories.length > 0 && <Typography fontSize={'18px'} fontWeight={700} variant='h1' lineHeight={'29px'} sx={{ marginLeft: isMobile ? '6%' : '16%', marginTop: '10px' }} >{ hotelData?.section_title?.["3"] || 'Categories'}</Typography> }
      <Box
        ml={isMobile ? 2 : '27%'}
        sx={{ marginTop: '1%', display: 'flex', overflowX: 'auto', width: isMobile ? '90%' : '47%', maxWidth: isMobile ? '90%' : '50%', justifyContent: isMobile ? 'start' : 'center', height: isMobile ? '188px' : '350px', whiteSpace: 'nowrap', '&::-webkit-scrollbar': {   display: 'none' }, scrollbarWidth: 'none' }}>
        {loading ? Array.from(new Array(3)).map((_, index) => (
          <CategoryLoader key={index} isMobile={isMobile} />
        )) : categories.map((data: any, index) => (
          <Link to={"/topProducts"} state={{name: data.name, homePageBanner: hotelData.image, categoryBanner: data.image}} key={index}>
            <Box sx={{ position: 'relative', p: 0.3, display: 'inline-block', flexShrink: 0, height: isMobile ? '175px' : '300px', width: isMobile ? '123px' : '220px', borderRadius: '14px', overflow: 'hidden' }}>
              <Box component="img" src={data.image || defaultCategory} sx={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '14px' }} />
              <Typography sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%', color: 'white', textAlign: 'center', p: 1, pl: 0, fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '29px', }} >
                {data.name}
              </Typography>
            </Box>
          </Link>
        ))}
      </Box><br/>
      { freeProds.length > 0 && <Typography fontSize={'18px'} fontWeight={700} variant='h1' lineHeight={'29px'} sx={{ marginLeft: isMobile ? '6%' : '16%', marginTop: '10px' }} >{ hotelData?.section_title?.["4"] || 'Hotel'}</Typography> }
      <Box ml={isMobile ? 2 : '17%'} sx={{ display: 'flex', overflowX: 'scroll', width: isMobile ? '88%' : '62%', maxWidth: isMobile ? '88%' : '62%', justifyContent: isMobile ? 'start' : 'center', height: isMobile ? '125px' : '190px', '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none' }}>
      { loading ? Array.from(new Array(4)).map((_, index) => (
          <HotelsLoader key={index} isMobile={isMobile} />
        )) : 
          (freeProds.map((value: any, index) => value.section_id === 4 && (
            <Link to={"/singleProduct"} state={value} style={{ textDecoration: 'none' }} key={index}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box p={0.5} sx={{ marginTop: '1%', height: isMobile ? '86px' : '142px', width: isMobile ? '86px' : '142px', objectFit: 'cover', flexShrink: 0, borderRadius: '14px' }} component="img" src={value.image} />
                <Typography fontSize={isMobile ? '11px' : '14px'} fontWeight={"900"} fontFamily={'Montserrat'} color={"#000000"} lineHeight={"29px"}>{value.eminity}</Typography>
              </Box>
            </Link>
          )))
      }
        </Box>
      { freeProds.length > 0 && <Typography fontSize={'18px'} fontWeight={700} variant='h1' lineHeight={'29px'} sx={{ marginLeft: isMobile ? '6%' : '16%', marginTop: '10px' }} >{ hotelData?.section_title?.["5"] || 'Events'}</Typography> }
      <Box sx={{ marginTop: '1%', ml: loading ? 0 : 2 }}>
        {loading ?
          Array.from(new Array(1)).map((_, index) => (
            <ImageLoader key={index} isMobile={isMobile} />
          ))
          :
          (freeProds.filter((value: any) => value.section_id === 5).length > 1 ? (
            <Slider {...settings1} slidesToShow={1}>
              {freeProds.map((value: any, index) => value.section_id === 5 && (
                <Link to={isEmpty(value.link) ? "/singleProduct" : value.link} state={value} key={index}>
                  <img src={value.image} height={isMobile ? '200px' : '500px'} width={isMobile ? '93%' : '1420px'} alt='londondrycleaners' style={{ maxWidth: '100%', objectFit: 'cover', borderRadius: '14px', marginLeft: isMobile ? '1%' : '13%', }} />
                </Link>
              ))}
            </Slider>
          ) : (
            <div>
              {freeProds.filter((value: any) => value.section_id === 5).map((value: any) => (
                <Link to={"/singleProduct"} state={value} key={value.id}>
                  <img key={value.id} src={value.image} height={isMobile ? '190px' : '500px'} width={isMobile ? '380px' : '1420px'} alt='londondrycleaners' style={{ display: 'block', maxWidth: '100%', objectFit: 'cover', borderRadius: '14px', marginLeft: isMobile ? '0' : '13%', marginBottom: '10px' }} />
                </Link>
              ))}
            </div>
          ))}
      </Box>
      <br />
      <br />
    </Box>
  )
}

export default Home
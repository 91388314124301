import React, { useEffect, useState } from 'react'
import { fetchProducts, getHomePageBanner } from '../../../apis/Api'
import { Box, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import '../Home/home.css'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ImageLoader, TopProductLoader } from '../../../utils/loader'

const useStyles = makeStyles({
  cardHeader: { fontFamily: 'Montserrat', fontSize: '32px', fontWeight: '600', lineHeight: '29px' },
  cardHeader2: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    // fontSize: 8,
    lineHeight: '29px'
  }
})

const TopProducts = () => {
  const styles = useStyles()
  const [products, setProducts] = useState([])
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { name, homePageBanner, categoryBanner } = location.state || {};
  // const category = state
  const [bannerLink, setBannerLink] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const fetchTTDproducts = async () => {
    try {
      setLoading(true)
      const response = await fetchProducts(name)
      setProducts(response.data)
    } catch (error) {
      console.log("🚀 ~ fetchProducts ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const fetchTTdBanner = async () => {
    try {
      const hotel_id = localStorage.getItem("hotel_id")

      const value = { type: "topProducts", hotel_id }
      const response = await getHomePageBanner(value)
      setBannerLink(response.data)
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    if (isEmpty(bannerLink)) {
      fetchTTdBanner()
    }
    if (products.length === 0) {
      fetchTTDproducts()
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      { loading ? <ImageLoader isMobile={isMobile}/> : <img src={ !categoryBanner ? homePageBanner : categoryBanner} height={isMobile ? '173px' : '500px'} width={isMobile ? '390px' : '1420px'} alt='image' style={{ maxWidth: '100%', height: isMobile ? '173px' : '500px', objectFit: 'cover', borderRadius: '14px' }} />}

      <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', width: isMobile ? '100%' : '70%', height: '5%', alignSelf: 'center' }}>
        <Typography fontSize={'18px'} fontWeight={700} variant='h1' lineHeight={'29px'} sx={{ marginLeft: '20px', marginTop: '10px' }} >Top Things To Do</Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Grid width={isMobile ? '100%' : '70%'} container m={1.2} mt={0} >
          { loading
            ? Array.from(new Array(4)).map((_, index) => (
              <TopProductLoader key={index} isMobile={isMobile} />
            ))
            : products.map((data: any, index) => (
            <Grid maxWidth={isMobile ? 'auto' : '262px'} py={2} px={1.3} xs={isMobile ? 6 : 3} key={index} >
              <Card sx={{ boxShadow: 5, borderRadius: 5, maxHeight: isMobile ? '220px' : '330px' }} onClick={() => window.open(data.link, "_blank")}>
                <CardMedia
                  sx={{ height: isMobile ? '117px' : '181px' }}
                  image={data.image}
                  title="image"
                />
                <CardContent sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Typography
                    fontSize={isMobile ? '12px' : '14px'}
                    fontWeight={700}
                    className={styles.cardHeader}
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {data.product_name}
                  </Typography>
                  <Typography mt={3} fontSize={'12px'} className={styles.cardHeader2}>Starting from</Typography>
                  <Typography fontSize={'14px'} fontWeight={700} className={styles.cardHeader}>INR {data.price}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  )
}

export default TopProducts
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { primaryColor } from '../components/Styles/styles';
import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { AdminLoginApi } from '../adminAPIs/adminAPIs';
import { useDispatch } from 'react-redux';
import { handleAdminDetails, updateAdminToken } from '../redux/redux';

const AdminLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Full Name is required'),
        password: Yup.string().required('Password is required')
    })
    const initialValues = {
        username: '',
        password: ''
    };

    const handleSubmit = async(values: any) => {
        try {
            const response = await AdminLoginApi(values)
            dispatch(updateAdminToken(response.data.token))
            dispatch(handleAdminDetails(response.data))
            navigate("/adminHome")

        } catch (error: any) {
            console.log("🚀 ~ handleSubmit ~ error:", error.response.data.message)
            alert(error.response.data.message || "Bad Request")
        }
    }
    return (

        <Box sx={{ width: '100vw', height: '75vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '10vh' }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (

                    <Form onSubmit={formik.handleSubmit}>
                        <Container sx={{ width: '552px', height: '350px', top: '308px', left: '677px', borderRadius: '10px 0px 0px 0px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: '20px' }}>
                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '40px', fontWeight: '500', lineHeight: '29px', textAlign: 'center', color: '#6E6E6E' }}>Login</Typography>
                            <TextField
                                sx={{ marginTop: '2%', width: '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' }, '& .MuiFormHelperText-root': { color: 'red' }, borderRadius: '10px' }}
                                id="username"
                                name="username"
                                placeholder='Username'
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                helperText={<ErrorMessage name="username" />}
                            />
                            <TextField
                                sx={{ marginTop: '-2%', width: '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' }, '& .MuiFormHelperText-root': { color: 'red' }, borderRadius: '10px' }}
                                id="password"
                                name="password"
                                placeholder='Password'
                                type='password'
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                helperText={<ErrorMessage name="password" />}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                sx={{
                                    height: '55px',
                                    width: '187px',
                                    fontSize: '16px',
                                    backgroundColor: primaryColor,
                                    ":hover": { backgroundColor: primaryColor },
                                    borderRadius: '47px'
                                }}
                            >
                                Save
                            </Button>
                        </Container>
                    </Form>
                )}
            </Formik>
        </Box>

    )
}

export default AdminLogin
import { Box, Button, Checkbox, FormControl, Grid, IconButton, MenuItem, Modal, Select, Slide, TextField, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { primaryColor } from '../components/Styles/styles';
import './forms.css';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleFormModal } from '../redux/redux';
import { addCategory, addFreeProduct, addProduct } from '../apis/AdminApi';
import { makeStyles } from '@mui/styles';
import JoditEditor from 'jodit-react';
import { createUser } from '../apis/Api';
import { fetchCategoryForSuperAdmin, fetchHotelsList } from '../apis/SuperAdminApi';
import { defaultHotelColumn } from '../utils/utils';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles({
    helperText: {
        backgroundColor: 'rebeccapurple',
        color: 'blanchedalmond'
    },
})

const Forms = (params: any) => {

    const styles = useStyles()
    const dispatch = useDispatch()
    const modal = useSelector((store: any) => store.openModal)
    const [store, setStore] = useState(modal)

    const Formbody = () => {
        const formRef = useRef<any>(null)
        const columns = params.columns
        const formName = params.formName
        const initialValues: any = {}
        const validationSchema: any = {}
        const [categories, setCategories] = useState([])
        const [hotelList, setHotelList] = useState([])
        const [imageLink, setImageLink] = useState<any>("")
        const fileInputRef = useRef<any>(null);
        const config: any = {
            readonly: false,
            placeholder: 'Start typings...',
            height: 300
        } 
        const columnsToHide = ["id", "_id", "hotel_id", "createdAt", "updatedAt","order"]
    
        const finalColumns = (formName === 'hotel' ? defaultHotelColumn: columns).filter((key: any) => !columnsToHide.includes(key.field))
    
        const descField = finalColumns.find((column: any) => column.field === 'description');
        const otherFields = finalColumns.filter((column: any) => column.field !== 'description');
        const filtertedColumns = descField ?  [...otherFields, descField] : otherFields;
        filtertedColumns.forEach((column: any) => {
            if(column.field === 'assigned_to'){
                initialValues[column.field] = [];
            }else{
                initialValues[column.field] = '';
            }
            if(column.field === "latitude" ){
                validationSchema[column.field] = Yup.string().matches(/^\d+(\.\d+)?$/, 'Invalid latitude format').required('Latitude is required')
            }
            else if(column.field === "longitude" ){
                validationSchema[column.field] = Yup.string().matches(/^\d+(\.\d+)?$/,'Invalid longitude format').required('Longitude is required')
            }
            else if(column.field === 'price' || column.field ===  'offer' || column.field === 'order'){
                validationSchema[column.field] = Yup.string().matches(/^\d+$/, `Invalid ${column.field} format`).required(`${column.headerName} is required`);
            }else if (column.field === 'section_id') {
                validationSchema[column.field] = Yup.number().oneOf([2, 4, 5], 'Available sections are only 2,4 or 5').required(`${column.headerName} is required`);
            }else if(column.field === 'assigned_to') {
                validationSchema[column.field] = Yup.array()
                .of(Yup.string().required('Hotel name is required'))
                .min(1, `${column.headerName} must have at least one hotel`)
                .required(`${column.headerName} is required`);
            }
            else if(column.field === 'link' || column.field === "image"){
            }
            else{
                validationSchema[column.field] = Yup.string().required(`${column.headerName} is required`);
            }
    
        });
    
        // Convert validationSchema to Yup object schema
        const formikValidationSchema = Yup.object().shape(validationSchema);
    
        const handleCancel =async () => {
            try {
                setImageLink('')
                formRef.current.setFieldValue('image','')
            } catch (error) {
                console.log("🚀 ~ handleCancel ~ error:", error)
                
            }
        }
    
        const handleFileChange = async(event: any) => {
    
            const file = event.target.files[0];
            setImageLink(file)
            formRef.current.setFieldValue('image','')
        };
    
        const handleIconClick = () => {
            fileInputRef.current.click();
        };
        
        const handleSubmit = async (values: any) => {
            try {
                const { latitude, longitude , ...filteredValues } = values;
                const updatedValues = {
                    ...filteredValues,
                    location: {
                        latitude: parseFloat(latitude), // Convert latitude to float
                        longitude: parseFloat(longitude), // Convert longitude to float
                    }
                }

                let formData: any
                formData = new FormData();

                if (formName === 'freeproducts'){

                    if (imageLink) {
                        formData.append('file', imageLink);
                    }
                    formData.append('values', JSON.stringify(updatedValues));
                    await addFreeProduct(formData,formName)

                }else if(formName === "hotel"){

                    await createUser(updatedValues)

                } else if (formName === 'tripXOXOproducts') {
                    
                    const newIds = hotelList.filter((val: any) => values.assigned_to.includes(val.hotel_name)) // Filter hotels whose names are in assigned_to
                        .map((val: any) => val._id);
                    const newValues = updatedValues
                    newValues.assigned_to = newIds
                    if (imageLink) {
                        formData.append('file', imageLink);
                    }
                    formData.append('values', JSON.stringify(newValues));
                    await addProduct(formData, formName)
                }
                else if (formName === 'tripXOXOcategory'){
                    const newIds = hotelList.filter((val: any) => values.assigned_to.includes(val.hotel_name)) // Filter hotels whose names are in assigned_to
                    .map((val: any) => val._id);
                    const newValues = updatedValues
                    newValues.assigned_to = newIds
                    if (imageLink) {
                        formData.append('file', imageLink);
                    }
                    formData.append('values', JSON.stringify(newValues));
                    await addCategory(formData, formName) 
                }
                params.setColumns([])
                dispatch(handleFormModal(false))
                // alert('Data added successfully')
            } catch (error: any) {
                console.log("🚀 ~ handleSubmit ~ error:", error)
            }
        }
        
    
        const getCategories = async () => {
            try {
                let response: any
                if(params.selectedRow?.type === "tripXOXOproducts"){
                    response = await fetchCategoryForSuperAdmin()
                    
                }else{
                    // response = await fetchCategories()
                }
                let resp: any = await fetchHotelsList()
                setHotelList(resp.data)
                console.log("🚀 ~ getCategories ~ response:", response)
                setCategories(response.data)
            } catch (error) {
                console.log("🚀 ~ getCategories ~ error:", error)
            }
        }
        useEffect(() => {
            if (categories.length === 0 && params.selectedRow.type !== "freeproducts") {
                getCategories()
            }
        }, [])
    
        useEffect(() => {
            if (store !== modal) {
                setStore(modal)
            }
        }, [modal])

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={formikValidationSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container sx={{ width: '842px', overflowY: 'hidden', height: '100%', marginTop: params?.selectedRow?.type === "freeproducts" ? '45%': 0 }}>
                            <div style={{ width: '100%', marginTop: formName === 'tripXOXOproducts' ? '15%': formName === 'hotel' ? '5%' :'1%', display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }}>
                                <div className='form-footer-header'>
                                    {params.formName === "tripXOXOproducts" ? "Add Product" : params.formName === "freeproducts" ? "Add Free Product" : "Add Category"}
                                    <CloseIcon onClick={() => dispatch(handleFormModal(false))} sx={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            {filtertedColumns.map((data: any, index: any) => (
                                <React.Fragment key={index}>
                                    {data.field === "category" ?
                                        <Grid item xs={12} md={6}>
                                            <div className='form-content'>
                                                Category
                                            </div>
                                            <FormControl fullWidth error={Boolean(formik.touched.category && formik.errors.category)}>
                                                <Select
                                                    className='form-textfield'
                                                    sx={{
                                                        marginTop: '2%',
                                                        width: '370px',
                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                                                        '& .MuiFormHelperText-root': { color: 'red' },
                                                    }}
                                                    id={data.field}
                                                    name={data.field}
                                                    value={formik.values.category}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    {
                                                        categories.map((category: any, index: any) => (
                                                            <MenuItem key={index} value={category.name || ''}>{category.name || ''}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {formik.touched.category && formik.errors.category ? (
                                                    <Typography style={{ fontSize: '12px', margin: 2 }} sx={{ color: 'red' }}>
                                                        {/* {formik} */}
                                                    </Typography>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                        :data.field === "assigned_to" ?
                                        <Grid item xs={12} md={6}>
                                            <div className='form-content'>
                                                Assign to
                                            </div>
                                            <FormControl fullWidth error={Boolean(formik.touched.assigned_to && formik.errors.assigned_to)}>
                                                <Select
                                                    className='form-textfield'
                                                    sx={{
                                                        marginTop: '2%',
                                                        width: '370px',
                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                                                        '& .MuiFormHelperText-root': { color: 'red' },
                                                    }}
                                                    multiple
                                                    id={data.field}
                                                    name={data.field}
                                                    value={formik.values[data.field]}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                                >
                                                    {
                                                        hotelList.map((hotel: any, index: any) => (
                                                            <MenuItem key={index} value={hotel.hotel_name || ''}>
                                                                <Checkbox checked={formik.values[data.field].indexOf(hotel.hotel_name || '') > -1} />
                                                                {hotel.hotel_name || ''}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {formik.touched.assigned_to && formik.errors.assigned_to ? (
                                                    <Typography style={{ fontSize: '12px', margin: 2 }} sx={{ color: 'red' }}>
                                                        Choose atleast any one
                                                    </Typography>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                        :
                                        data.field === "image" ?
                                            <>
                                                {
                                                    imageLink ? 
                                                        <Grid item xs={12} md={6}>
                                                            <div className='form-content'>
                                                                Image Selected:
                                                            </div>
                                                            <img src={URL.createObjectURL(imageLink)} alt="Selected" style={{ height: '15%', width: '15%', marginTop: '2%' }} />
                                                            <IconButton
                                                                color="primary"
                                                                onClick={handleCancel}
                                                                sx={{ marginTop: '2%', marginLeft: '10%' }}
                                                            >
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    :
                                                    <Grid item xs={12} md={6}>
                                                        <div className='form-content'>
                                                            Image
                                                        </div>
                                                        <TextField
                                                            placeholder="Enter or paste image link"
                                                            className='form-textfield'
                                                            sx={{
                                                                marginTop: '2%',
                                                                width: '340px',
                                                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                                                                '& .MuiFormHelperText-root': { color: 'red' }
                                                            }}
                                                            id={data.field}
                                                            name={data.field}
                                                            value={formik.values.image}
                                                            onChange={formik.handleChange}
                                                            // onChange={handleFileChange}
                                                            helperText={<ErrorMessage className={styles.helperText} name="image" />}
                                                        />
                                                        <input
                                                            accept="image/*"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                            onChange={handleFileChange}
                                                        />
                                                        <IconButton
                                                            color="primary"
                                                            onClick={handleIconClick}
                                                            sx={{ marginTop: '2%' }}
                                                        >
                                                            <CloudUploadIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={handleCancel}
                                                            sx={{ marginTop: '2%' }}
                                                        >
                                                            {/* <CloudUploadIcon /> */}
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </>
                                            :
                                        data.field === "description" ?
                                            <Grid item xs={12} md={12}>
                                                <div className='form-content' style={{ marginTop: '2%' }}>
                                                    {data.headerName}
                                                </div>
                                                <JoditEditor
                                                    className='joditor'
                                                    config={config}
                                                    value={formik.values.description}
                                                    onChange={(content) => formik.setFieldValue('description', content)}
                                                /></Grid> :
                                            <Grid item xs={12} md={6}>
                                                <div className='form-content'>
                                                    {data.headerName}
                                                </div>
                                                <TextField placeholder={data.field === "section_id" ? "2, 4 or 5" : ""} className='form-textfield' sx={{ marginTop: '2%', width: '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' }, '& .MuiFormHelperText-root': { color: 'red' } }} id={data.field} name={data.field} value={formik.values.id} onChange={formik.handleChange} helperText={<ErrorMessage className={styles.helperText} name={data.field} />}
                                                />
                                            </Grid>
                                    }
                                </React.Fragment>
                            ))}
                        </Grid>
                        {/* <Grid container sx={{ justifyContent: 'center', marginTop: '25px' }}> */}
                        <Button color="primary" variant="contained" type="submit" sx={{ margin: '5%', marginLeft: '35%', height: '55px', width: '187px', fontSize: '16px', backgroundColor: primaryColor, ":hover": { backgroundColor: primaryColor } }}>
                            Submit
                        </Button>
                        {/* </Grid> */}
                        <br />
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <Modal
            open={store}
            onClose={() => dispatch(handleFormModal(false))}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{ backgroundColor: '#ffffff', borderRadius: '8px',  maxHeight: '70%',minHeight: '50%', width: '55%', marginLeft: '18%', marginTop: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'scroll', '&::-webkit-scrollbar': { display: params?.selectedRow?.type === "freeproducts" ? 'scroll': 'none' },
                    '-ms-overflow-style': 'none',  /* IE and Edge */
                    'scrollbar-width': params?.selectedRow?.type === "freeproducts" ? 'scroll': 'none' }}>
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
    )
}

export default Forms
import { createSlice, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
};

const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: {
        adminToken: "",
        adminDetails: {},
        openModal: false,
        editFormdetails: {
            visible: false,
            data: {}
        },
        superuserToken: ""
    },
    reducers: {
        updateAdminToken: (state, action) => {
            state.adminToken = action.payload
        },
        handleAdminDetails: (state, action) => {
            state.adminDetails = action.payload
        },
        handleFormModal: (state, action) => {
            state.openModal = action.payload
        },
        handleEditFormdetails: (state, action) => {
            state.editFormdetails = action.payload
        },
        updateSuperUserToken: (state, action) => {
            state.superuserToken = action.payload
        },
        handleLogout: (state) => {
            state.adminToken = ""
            state.adminDetails = {}
            state.openModal = false
            state.editFormdetails = { visible: false, data: {}}
            state.superuserToken = ""
        }
    },
})

const persistedReducer = persistReducer(persistConfig, authSlice.reducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const {
    updateAdminToken,
    handleAdminDetails,
    handleFormModal,
    handleEditFormdetails,
    handleLogout,
    updateSuperUserToken
} = authSlice.actions

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store);

export default store;
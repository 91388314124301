// src/axiosInstance.js
import axios from 'axios';
import store from '../redux/redux';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
  // baseURL: "http://localhost:3000/api/v1", // Replace with your API base URL
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`, // Replace with your API base URL
  timeout: 5000, // Set a timeout limit
  headers: {
    'Content-Type': 'application/json',
    // Add other headers you need
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Perform actions before the request is sent, like adding auth tokens
    const state: any = store.getState();
    const superuserToken = state.superuserToken
    const token = state.adminToken;
    const hotel_id = localStorage.getItem("hotel_id")   //will get value if user came through url or not any admin/superadmin

    if(superuserToken){
      config.headers.Authorization = `bearer ${superuserToken}`;
    }
    else if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    if(!config.data){
      config.data = {
        ...config.data,
        hotel_id,
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response.data,
  error => {
    console.log("🚀 ~ error:", error)
    // Handle errors globally
    if(error?.message==='Network Error'){
      alert('Network Error')
    }
    else if (error.response.status === 401) {
      // Example: Redirect to login page if unauthorized
      window.location.href = '/';
    }
    else{
      alert(error.response.data.message)
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

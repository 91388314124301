import React, { useEffect, useState } from 'react'
import SuperAdminNavbar from './SuperAdminNavbar'
import { Box, Container } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { fetchProductsForSuperAdmin } from '../apis/SuperAdminApi'
import { superAdminProductsDefaultColumn } from '../utils/utils'
import { useSelector } from 'react-redux'
import Forms from '../forms/Forms'
import EditForm from '../forms/EditForm'

const TripXOXOproductsPage = () => {

  const apiRef = useGridApiRef();
  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [deleteIcon, setDeleteIcon] = useState(false)
  const [selectedRow, setSelectedRow] = useState({ type: "tripXOXOproducts", id: [] })
  // const [initialState, setInitialState] = useState<any>([]); //To check whether user has updated the order of proucts
  const modal = useSelector((store: any) => store.openModal)
  const editModal = useSelector((store: any) => store.editFormdetails.visible)
  const [loading, setLoading] = useState(false)

  const handleChange = (selectionModel: any) => {
    if (selectionModel.length > 0) setDeleteIcon(true)
    else setDeleteIcon(false)
    let objectsTotDelete: any = { type: "tripXOXOproducts", id: [] }
    selectionModel.forEach((record: any) => {
      objectsTotDelete.id.push(rows[record - 1]);
    });
    setSelectedRow(objectsTotDelete)
  }

  const fetchProducts = async () => {
    try {
      setLoading(true)
      const response = await fetchProductsForSuperAdmin()
      if (response.data.length === 0) {
        setColumns(superAdminProductsDefaultColumn)
        setRows([])
        setSelectedRow({ type: "tripXOXOproducts", id: [] })
        setLoading(false)
        return
      }

      const keys = Object.keys(response.data[0]);
      const fieldsToHide = ['location', '__v', 'createdAt', 'updatedAt', 'assigned_to','image'];

      const filteredKeys = keys.filter(key => !fieldsToHide.includes(key));
      const generatedColumns: any = [
        {
          field: 'id',
          headerName: 'ID',
          width: 80
        },
        ...filteredKeys.map(key => ({
          field: key,
          headerName: key === '_id' ? 'Product_id' : key.charAt(0).toUpperCase() + key.slice(1),
          width: key === 'price' ? 100 : key === 'offer' ? 90 : key === 'order' ? 90 : 150
        })),
        {
          field: 'latitude',
          headerName: 'Latitude',
          width: 120
        },
        {
          field: 'longitude',
          headerName: 'Longitude',
          width: 120
        },
        {
          field: 'assigned_to',
          headerName: 'Assigned to',
          width: 120,
        },
        {
          field: 'image',
          headerName: 'Image',
          width: 120,
        }
      ];
      setColumns(generatedColumns);

      const generatedRows = response.data.map((row: {
        location: any; _id: any; assigned_to: any
      }, index: any) => ({
        id: index + 1,
        ...row,
        latitude: row.location.latitude,
        longitude: row.location.longitude,
        // assigned_to: row.assigned_to.map((e: any)=>e.hotel_name)
      }))
      setRows(generatedRows);
      // setInitialState(generatedRows)
    } catch (error) {
      console.log("🚀 ~ fetchProducts ~ error:", error)
    }finally{
      setLoading(false)
    }
  }

  const handleUnselectAll = () => {
    apiRef.current.setRowSelectionModel([]);
    setDeleteIcon(false);  // Optionally reset the delete icon state
    setSelectedRow({ type: "tripXOXOproducts", id: [] });  // Reset selected rows
  };

  useEffect(() => {
    if (columns.length === 0) {
      fetchProducts()
    }
  }, [columns])

  const hasChanges = () => { return false }

  const filteredColumns = columns.filter((col: any) => col.field !== 'assigned_to');
  
  return (
    <>
      <SuperAdminNavbar hasChanges={hasChanges} handleUnselectAll={handleUnselectAll} selectedRow={selectedRow} deleteIcon={deleteIcon} fetchProducts={fetchProducts} />
      {modal && <Forms setColumns={setColumns} columns={columns} formName={"tripXOXOproducts"} selectedRow={selectedRow} />}
      {editModal && <EditForm handleUnselectAll={handleUnselectAll} selectedRow={selectedRow} setColumns={setColumns} columns={columns} formName={"tripXOXOproducts"} />}

      <Box sx={{ width: '100vw', height: '82vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '1%' }}>
        <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column', marginTop: '1%' }}>
          <DataGrid
            loading={loading}
            apiRef={apiRef}
            rows={rows}
            columns={filteredColumns}
            sx={{
              flexGrow: 1, // Make the DataGrid grow to fill the Container
              width: '100%',
              border: 'none'
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={handleChange}
          />
        </Container>
      </Box>
    </>
  )
}

export default TripXOXOproductsPage